// in chartjs v2 plugins should be registered
import "chartjs-plugin-annotation";

import * as ChartAnnotation from "chartjs-plugin-annotation";
import { ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";
import { QuotationService } from "trade_quotation_lib";
import useSWR from "swr";
import { firstValueFrom } from "rxjs";
import { getKlineIntervalParam, getFetchStartTimeAccTimeInterval, transformKlineData, getLastValue, getPixelHeight } from "./utils";
import { useEffect, useRef } from "react";
import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { ShareOrderDataParams } from "../types";
import { getOrderBase, getOrderQuote } from "../utils";
import { getAnnotations, getBufferSpace } from "./utils";
import str from "src/i18n/useStr";

// in chartjs v2 plugins are not in the chartjs type definition
interface ChartOptionsWithAnnotation extends ChartOptions {
    annotation?: ChartAnnotation.AnnotationOptions;
}

const klineFetcher = (start: number, base: string, quote: string) => {
    const now = Date.now();

    const klineIntervalParam = getKlineIntervalParam(start, now);

    const fetchStartTime = getFetchStartTimeAccTimeInterval(start, now);

    return firstValueFrom(
        QuotationService.getKlineData({
            base,
            quote,
            start: fetchStartTime,
            end: Date.now(),
            interval: klineIntervalParam,
        }),
    );
};

type PriceChartProps = {
    order: ShareOrderDataParams;
    orderTime: number;
};

export const PriceChart = ({ order, orderTime }: PriceChartProps) => {
    const base = getOrderBase(order.symbol);
    const quote = getOrderQuote(order.symbol);

    const { data, error, isLoading } = useSWR([orderTime, base, quote], ([millisecondsOrderTime, base, quote]) =>
        klineFetcher(millisecondsOrderTime, `${base}.PERP`, quote),
    );
    const chartRef = useRef<Line>(null);

    useEffect(() => {
        // console.log("price chart instance", chartRef.current);

        if (chartRef.current) {
            const instance = chartRef.current.chartInstance;

            // const data = instance.data;
            // console.log(instance.getDatasetMeta(0));

            const a = getPixelHeight(instance, 60000);
            console.log(a);
        }
    }, []);

    if (error) {
        return <CloseCircleOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} />;
    }

    if (isLoading) {
        return <LoadingOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} />;
    }

    // protection
    if (data?.data.length === 0) {
        return <div>{str("no_data")}</div>;
    }

    const chartData = transformKlineData(data);
    const lastValue = getLastValue(chartData);
    const price = order.price;

    const annotations = getAnnotations(chartData, lastValue, price);

    const { min: yMin, max: yMax } = getBufferSpace(chartData);

    return (
        <Line
            ref={chartRef}
            data={{
                datasets: [
                    {
                        backgroundColor: "transparent", // 设置为透明
                        borderColor: "#27282A",
                        borderWidth: 1.5,
                        // 不需要展示数据点
                        pointRadius: 0,
                        data: chartData,
                    },
                ],
            }}
            options={
                {
                    annotation: {
                        annotations,
                    },
                    scales: {
                        xAxes: [
                            {
                                display: false,
                                // offset: false,
                                // gridLines: {
                                //     display: false,
                                // },
                                // ticks: {
                                // autoSkip: true,
                                // autoSkipPadding: 20,
                                // maxTicksLimit: 2,
                                // callback: function (value, index, values) {
                                //     // 根据需要对标签进行格式化
                                //     // 例如，你可以只缩短过长的标签
                                //     if (value.length > 10) {
                                //         return value.substring(0, 10) + "..."; // 以省略号缩短
                                //     }
                                //     return value;
                                // },
                                // afterFit: function (scaleInstance) {
                                //     scaleInstance.width = 100; // 将轴的宽度固定为100px
                                // },
                                type: "time",
                                // position: "bottom",
                                // maxRotation: -90,
                                // maxRotation: 0,
                                // minRotation: 0,
                                // },
                            },
                        ],
                        yAxes: [
                            {
                                display: false,
                                gridLines: {
                                    display: false,
                                },
                                ticks: {
                                    min: yMin,
                                    max: yMax,
                                },
                            },
                        ],
                    },
                    layout: {
                        padding: {},
                    },
                    legend: {
                        display: false,
                    },
                    // 填满 container
                    maintainAspectRatio: false,
                } as ChartOptionsWithAnnotation
            }
        />
    );
};
