import { queryLang } from "../utils";
import enUS from "./strings/en-US";
import ko from "./strings/ko";
import zhCN from "./strings/zh-CN";
import zhTW from "./strings/zh-TW";
import es from "./strings/es";
import ru from "./strings/ru";
import de from "./strings/de";
import { langEnum } from "./types";

const str = (strKey, variable?: any) => {
    const lang = queryLang();
    let str_string = strKey;
    if (lang === langEnum.cn) {
        str_string = zhCN[strKey] || enUS[strKey] || strKey;
    } else if (lang === langEnum.tw) {
        str_string = zhTW[strKey] || enUS[strKey] || strKey;
    } else if (lang === langEnum.ko) {
        str_string = ko[strKey] || enUS[strKey] || strKey;
    } else if (lang === langEnum.es) {
        str_string = es[strKey] || enUS[strKey] || strKey;
    } else if (lang === langEnum.de) {
        str_string = de[strKey] || enUS[strKey] || strKey;
    } else if (lang === langEnum.ru) {
        str_string = ru[strKey] || enUS[strKey] || strKey;
    } else {
        str_string = enUS[strKey] || strKey;
    }

    if (variable) {
        str_string = str_string
            .split("$")
            .map((str) => {
                return variable[str] || str;
            })
            .join("");
    }

    return str_string;
};

export default str;
