import React from "react";
import { BasicLineParamInfo } from "trade_lib_wrapper";
import { H4, WrapperSpaceBetween } from "../../components/StyleComponent";

export interface BasicLineParamsProps {
    data: BasicLineParamInfo[];
}

const BasicLineParams = (props: BasicLineParamsProps) => {
    const { data } = props;

    if (!data?.length) return null;
    return (
        <div>
            {data.map((item) => {
                return (
                    <WrapperSpaceBetween style={{ alignItems: "start" }} key={item.title}>
                        <H4 style={{ minWidth: 60 }}>{item.title}</H4>
                        <H4 style={{ textAlign: "right" }}>{item.value}</H4>
                    </WrapperSpaceBetween>
                );
            })}
        </div>
    );
};

export default BasicLineParams;
