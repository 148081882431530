import React from "react";
import Space from "../../components/Space";
import str from "../../i18n/useStr";
import PButton from "../PButton";
import { StickyFooter, WrapperColumn } from "../StyleComponent";

const CopyButton = (props) => {
    const { title = str("copy_in_one_click"), onPress, sticky } = props;

    if (sticky) {
        return (
            <StickyFooter>
                <WrapperColumn style={{ backgroundColor: "#F8F9FB", height: 82 }}>
                    <div style={{ width: "100%", paddingLeft: 12, paddingRight: 12 }}>
                        <PButton text={title} onPress={onPress} sticky={sticky} />
                    </div>
                </WrapperColumn>
            </StickyFooter>
        );
    }

    return (
        <WrapperColumn>
            <Space size={50} />
            <PButton text={str("copy_in_one_click")} onPress={onPress} sticky={sticky} />
            <Space size={50} />
        </WrapperColumn>
    );
};

export default CopyButton;
