import * as React from "react";
import { RadiusWrapper } from "../components/StyleComponent/index";
import CoinListV2 from "./CoinLIstV2";
import Space from "../components/Space";
import BasicLineParams from "./BasicLineParams";
import { TemplateV2Info, UIType, CoinListData, BasicLineParamInfo, CoinListDataItem } from "trade_lib_wrapper";
import Title from "./Title";

export default function SRParamsInfo({ data }: { data: TemplateV2Info[] }) {
    const coinListData = React.useMemo(() => {
        const coinListData = data.find((item) => item.uiType === UIType.coinList)?.data as CoinListData;
        return (coinListData?.details || []) as CoinListDataItem[];
    }, [data]);

    const paramListData = React.useMemo<BasicLineParamInfo[]>(() => {
        return (data.find((item) => item.uiType === UIType.paramList)?.data || []) as BasicLineParamInfo[];
    }, [data]);

    const hasTitle = React.useMemo(() => {
        return data.find((item) => item.uiType === UIType.title);
    }, [data]);

    return data?.length > 0 ? (
        <>
            {hasTitle && <Title />}
            <RadiusWrapper style={{ marginTop: 8, padding: 12 }}>
                <CoinListV2 data={coinListData} />
                <Space size={12} />
                <BasicLineParams data={paramListData} />
            </RadiusWrapper>
        </>
    ) : null;
}
