import "antd/dist/antd.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./axios_config";
import "./index.css";
import CopyBotShareTemplate from "./templates/CopyBot";
import { FutureGrid } from "./templates/FutureGrid";
import GridV3RobotShareTemplate from "./templates/GridV3Robot";
import MartingaleShareTemplate from "./templates/Martingale/index";
import TemplateV2 from "./templates/TemplateV2";
import { CopyFutures } from "./templates/CopyFutures";
import { initExchangeDataProvider } from "./initExchangeDataProvider";
import { FutureGridV2 } from "./templates/FutureGridV2";
import { SymbolProvider, SymbolDataProvider } from "trade_quotation_lib";
import { SR } from "./templates/SR";

// init for QuotationService.getKlineData functioning
initExchangeDataProvider();
SymbolDataProvider.init();

ReactDOM.render(
    <SymbolProvider>
        <Router>
            <Switch>
                <Route exact path={"/martingale/:share_id"} component={MartingaleShareTemplate} />
                <Route exact path={"/grid/:share_id"} component={GridV3RobotShareTemplate} />
                <Route exact path={"/share/:share_id"} component={TemplateV2} />
                <Route exact path={"/copy_bot/:order_type/:order_id"} component={CopyBotShareTemplate} />
                <Route exact path={"/futures-grid/:share_id"} component={FutureGrid} />
                {/** 合约网格 分享分润 */}
                <Route exact path={"/s/:share_id"} component={FutureGridV2} />
                {/** 现货网格 分享分润 */}
                <Route exact path={"/a/:share_id"} component={FutureGridV2} />
                <Route exact path={"/copy/futures/:share_id"} component={CopyFutures} />
                {/* 屯币宝 分享落地页 */}
                <Route exact path={"/sr/:share_id"} component={SR} />
            </Switch>
        </Router>
    </SymbolProvider>,
    document.getElementById("root"),
);
