const strings = {
    confirm_copy_order_closed_title: "Bot cerrado",
    confirm_copy_order_closed_continue: "El trader ha cerrado este bot. ¿Estás seguro de seguir copiándolo?",
    button_cancel: "Cancelar",
    subscription_success_confirm: "Copiar",
    futures_grid_v2_share_title: "Grid bot de futuros de $name$",
    trade_trigger_price: "Precio de activación",
    future_init_price: "Precio inicial",
    order_state_not_triggered: "No activado",
    future_current_price: "Precio actual",
    grid_position_share_ratio_percent: "Tarifa de profit sharing",
    futures_grid_v2_total_profit: "PNL actual",
    futures_grid_v2_grid_profit_apr: "Anualizada de arbitraje del bot",
    lasting: "Tiempo de operación",
    futures_grid_v2_order_create_time: "Tiempo de creación",
    futures_grid_v2_create_order: "Copia ahora",
    grid_futures_invited_only_tip: "Este bot solo lo pueden seguir los usuarios invitados de $creator$",
    futures_grid_v2_share_code: "Code de invitación de $name$",
    grid_futures_share_link_qr_code: "Escanea y copia",
    grid_futures_order_closed: "El trader ha cerrado el bot.",
};

export default strings;
