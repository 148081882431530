import React from "react";
import { H3 } from "../../components/StyleComponent";
import str from "../../i18n/useStr";

const Title = () => {
    return (
        <div style={{ marginTop: 20, marginBottom: 10, alignItems: "flex-start", display: "flex", paddingLeft: 18 }}>
            <H3>{str("parameters")}</H3>
        </div>
    );
};

export default Title;
