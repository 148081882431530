import axios from "axios";
import useSWR from "swr";
import { GeneralResponseType } from "src/use/types";
import { ShareOrderData } from "./types";
import CONSTANTS from "src/Constants";

export const useShareOrderData = (shareOrderId: string) => {
    return useSWR(shareOrderId, (shareOrderId: string) =>
        axios.get<GeneralResponseType<ShareOrderData>>(`${CONSTANTS.shareApi}?id=${shareOrderId}`).then((res) => res.data),
    );
};
