type TimeRangeProps = {
    start: number;
    end?: number;
};

export const TimeRange = ({ start, end = Date.now() }: TimeRangeProps) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "8px",
                color: "#A1A2AA",
            }}
        >
            <div>{new Date(start).toLocaleString()}</div>
            <div>{new Date(end).toLocaleString()}</div>
        </div>
    );
};
