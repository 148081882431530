import React from "react";
import OrderVerParamItem from "src/components/OrderVerParamItem";
import str from "src/i18n/useStr";
import { useTickerIndex } from "./useTickerIndex";

export const CurrentPrice = ({ base, quote }: { base: string; quote: string }) => {
    const {
        data: currentPriceResp,
        isLoading,
        error,
    } = useTickerIndex({
        base,
        quote,
    });

    if (isLoading || !currentPriceResp) {
        return <OrderVerParamItem title={str("future_current_price")} value={"~"} />;
    }

    if (error) {
        return null;
    }

    const { price } = currentPriceResp.data[0];

    return <OrderVerParamItem title={str("future_current_price")} value={`${price} ${quote}`} />;
};
