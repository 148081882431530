import { of, Observable, throwError } from "rxjs";
import { ILocalStorage } from "trade_utils_lib";
import { getTradeDependencyInstance } from "trade_utils_lib";
import { ExchangeSymbol, ExchangeTicker } from "TradeAPILib";

export function initExchangeDataProvider() {
    getTradeDependencyInstance().setTradeDependency({
        getAccessToken(force: boolean | undefined): Observable<string> {
            return of("");
        },
        getIsChina(): boolean {
            return false;
        },
        getMarketSymbol(market: string): Array<ExchangeSymbol> {
            return [];
        },
        getRSAPublicKey(): Observable<string> {
            return of("");
        },
        getSymbolInfo(symbol: string, market: string | undefined): ExchangeSymbol | undefined {
            return undefined;
        },
        getTickerWsStream(): Observable<Map<string, ExchangeTicker>> {
            return throwError(() => "not implemented");
        },
        getTradingToken(): Observable<any> {
            return throwError(() => "not implemented");
        },
        getUserId(): Observable<string | undefined> {
            return of(undefined);
        },
        instanceLocalStorage(): ILocalStorage {
            return {
                getItem(key: string): Promise<string | null> {
                    return new Promise<string | null>((resolve) => {
                        resolve(window.localStorage.getItem(key));
                    });
                },
                removeItem(key: string): Promise<void> {
                    return new Promise<void>((resolve) => {
                        window.localStorage.removeItem(key);
                        resolve();
                    });
                },
                setItem(key: string, value: string): Promise<void> {
                    return new Promise<void>((resolve) => {
                        try {
                            window.localStorage.setItem(key, value);
                            resolve();
                        } catch (e) {
                            console.error(e);
                            resolve();
                        }
                    });
                },
            };
        },
        getRunTimeHost: () => {
            return {
                klineHost: "/kline",
                publicApiHost: "/apis/papi/v1",
            };
        },
    });
}
