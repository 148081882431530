import axios from "axios";
import Constants from "src/Constants";
import { BUOrder, OrderShareResponse } from "src/use/types";
import useSWR from "swr";

type UserInfo = {
    nickname: string;
    avatar: string;
};

type FutureShareData = {
    userInfo: UserInfo;
    buOrder: BUOrder;
};

const fetcher = (url: string) => axios.get<OrderShareResponse>(url).then((res) => res.data);

export const useFutureShareData = (shareId: string) => {
    const url = `${Constants.tradeServiceHost}/orders/order_share/${shareId}/`;

    const { data, isLoading, error } = useSWR(url, fetcher, {
        errorRetryCount: 3,
    });

    return {
        data,
        isLoading,
        error,
    };
};

export const getFutureShareData = (futureOrderShareResponse: OrderShareResponse): FutureShareData => {
    const futureData = futureOrderShareResponse.data;

    const buOrder = futureData.bu_order;
    const userInfo = futureData.user_info;

    return {
        userInfo: {
            nickname: userInfo.nickname,
            avatar: userInfo.head_path,
        },
        buOrder,
    };
};
