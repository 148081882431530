import React from "react";
import styled from "styled-components";
import { H2, H4, WrapperColumn } from "../../components/StyleComponent";
import str from "../../i18n/useStr";
import { useGetValidResource } from "../../use";
import CONSTANTS from "../../Constants";

const DEFAULT_AVATAR = "pionex_app_default.png";

const Avatar = styled.div`
    width: 7.2rem;
    height: 7.2rem;
    border-radius: 50%;
    background: no-repeat;
    background-position: center;
    background-size: contain;
`;

export interface ShareUserInfoProps {
    avatar: string;
    nickname: string;
    symbol: string;
    strategy: string;
}

const ShareUserInfo = (props) => {
    const { nickname, avatar, strategy, symbol } = props;
    const host = CONSTANTS.avatarLocationOrigin;
    const url = useGetValidResource(`${host}${avatar}`, `${host}${DEFAULT_AVATAR}`);
    return (
        <WrapperColumn style={{ marginTop: 20 }}>
            <Avatar style={url && { backgroundImage: `url(${url})` }} />
            <H2 style={{ marginTop: 12 }}>{str("share_nickname", { nickname: nickname })}</H2>
            <H4 style={{ margin: "2px 20px 0" }}>
                {str("share_introduce", {
                    symbol: ` ${symbol} `,
                    strategy_bot: str(strategy),
                })}
            </H4>
        </WrapperColumn>
    );
};

export default ShareUserInfo;
