import { ShareOrderDataParams } from "../types";
import { ElementType } from "react";
import { InfoRow } from "./InfoRow";
import str from "src/i18n/useStr";
import { TriggerPriceType, CustomOrderType } from "trade_futures_lib";

type InfoPanelComponentProps = {
    order: ShareOrderDataParams;
};

const SPACE = " ";

/*------------------------------------------------------*/
const getGridMode = (gridType: ShareOrderDataParams["grid_type"]) => {
    if (gridType === "geometric") return str("copy_futures_grids_geometric");
    if (gridType === "arithmetic") return str("copy_futures_grids_arithmetic");

    return "";
};

export const GridOrder = ({ order }: InfoPanelComponentProps) => {
    const gridMode = getGridMode(order.grid_type);
    return (
        <>
            <InfoRow>
                <div>{str("copy_futures_lower_limit")}</div>
                <div>{order.grid_low}</div>
            </InfoRow>
            <InfoRow>
                <div>{str("copy_futures_upper_limit")}</div>
                <div>{order.grid_high}</div>
            </InfoRow>
            <InfoRow>
                <div>{str("copy_futures_grid_mode")}</div>
                <div>
                    {order.grid_count}
                    {SPACE}
                    {str("copy_futures_grids")}
                    {SPACE}
                    {gridMode}
                </div>
            </InfoRow>
            {order.tpsl && order.tpsl.tpPrice && (
                <InfoRow>
                    <div>{str("profit_price")}</div>
                    <div>{order.tpsl.tpPrice}</div>
                </InfoRow>
            )}

            {order.tpsl && order.tpsl.slPrice && (
                <InfoRow>
                    <div>{str("loss_price")}</div>
                    <div>{order.tpsl.slPrice}</div>
                </InfoRow>
            )}
        </>
    );
};

/*------------------------------------------------------*/
export const LimitOrder = ({ order }: InfoPanelComponentProps) => {
    return (
        <>
            <InfoRow>
                <div>{str("copy_futures_open")}</div>
                <div>
                    {str("trade_limit")}
                    {SPACE}
                    {order.price}
                </div>
            </InfoRow>

            {order.tpsl && order.tpsl.tpPrice && (
                <InfoRow>
                    <div>{str("take_profit_price")}</div>
                    <div>{order.tpsl.tpPrice}</div>
                </InfoRow>
            )}
            {order.tpsl && order.tpsl.slPrice && (
                <InfoRow>
                    <div>{str("stop_loss_price")}</div>
                    <div>{order.tpsl.slPrice}</div>
                </InfoRow>
            )}
        </>
    );
};

/*------------------------------------------------------*/
export const MarketOrder = ({ order }: InfoPanelComponentProps) => {
    return (
        <>
            <InfoRow>
                <div>{str("copy_futures_open")}</div>
                <div>{str("trade_market")}</div>
            </InfoRow>

            {order.tpsl && order.tpsl.tpPrice && (
                <InfoRow>
                    <div>{str("take_profit_price")}</div>
                    <div>{order.tpsl.tpPrice}</div>
                </InfoRow>
            )}
            {order.tpsl && order.tpsl.slPrice && (
                <InfoRow>
                    <div>{str("stop_loss_price")}</div>
                    <div>{order.tpsl.slPrice}</div>
                </InfoRow>
            )}
        </>
    );
};

/*------------------------------------------------------*/
// currently only limited type are supported
const triggerMode: Record<TriggerPriceType, string> = {
    [TriggerPriceType.LAST_PRICE]: str("copy_futures_latest_price_triggered"),
    [TriggerPriceType.MARK_PRICE]: str("copy_futures_mark_price_triggered"),
};

const openingPositionMode = {
    [CustomOrderType.LIMIT]: str("trade_limit"),
    [CustomOrderType.MARKET_QTY]: str("trade_market"),
    [CustomOrderType.MARKET_AMT]: str("trade_market"),
};

const getOpeningPositionMode = (orderType: CustomOrderType) => {
    if (orderType !== CustomOrderType.LIMIT && orderType !== CustomOrderType.MARKET_QTY && orderType !== CustomOrderType.MARKET_AMT)
        return str("other_type");

    return openingPositionMode[orderType];
};

const getOpeningPositionPrice = (openingMode: string, orderPrice?: string) => {
    if (openingMode === str("trade_limit") && orderPrice) {
        return orderPrice;
    }

    return "";
};

export const TriggerOrder = ({ order }: InfoPanelComponentProps) => {
    const triggerModeTitle = order.trigger_price_type ? triggerMode[order.trigger_price_type] : "";

    const openingMode = getOpeningPositionMode(order.type);
    const openingPrice = getOpeningPositionPrice(openingMode, order.price);

    return (
        <>
            <InfoRow>
                <div>{triggerModeTitle}</div>
                <div>{order.trigger_price}</div>
            </InfoRow>
            <InfoRow>
                <div>{str("copy_futures_open")}</div>
                <div>
                    {openingMode}
                    {SPACE}
                    {openingPrice}
                </div>
            </InfoRow>

            {order.tpsl && order.tpsl.tpPrice && (
                <InfoRow>
                    <div>{str("take_profit_price")}</div>
                    <div>{order.tpsl.tpPrice}</div>
                </InfoRow>
            )}
            {order.tpsl && order.tpsl.slPrice && (
                <InfoRow>
                    <div>{str("stop_loss_price")}</div>
                    <div>{order.tpsl.slPrice}</div>
                </InfoRow>
            )}
        </>
    );
};

/*------------------------------------------------------*/

export const InfoPanel = ({ order }: InfoPanelComponentProps) => {
    const Component = infoPanelComponent[order.orderType];

    if (Component) return <Component order={order} />;
    return <div>{str("not_supported_type")}</div>;
};

const infoPanelComponent: Record<ShareOrderDataParams["orderType"], ElementType<InfoPanelComponentProps>> = {
    grid: GridOrder,
    limit: LimitOrder,
    market: MarketOrder,
    trigger: TriggerOrder,
};
