import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Spin } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CopyButton from "src/components/CopyButton";
import OpenAppLoading from "src/components/OpenAppLoading";
import OrderAnnualized from "src/components/OrderAnnualized";
import OrderVerParamItem from "src/components/OrderVerParamItem";
import ShareHeader from "src/components/ShareHeader";
import Space from "src/components/Space";
import { H3, RadiusWrapper, Wrapper, WrapperColumn } from "src/components/StyleComponent";
import str from "src/i18n/useStr";
import bot_order_none_white from "src/images/bot_order_none_white.png";
import { handleFutureGridShareClick, isMobile } from "src/utils/index";
import { ShareUserInfo } from "../GridV3Robot/ShareUserInfo";
import { calculateAnnualized, calculateProfitPercent, getLossStopInfo, getPriceRange, getProfitStopInfo } from "./calculation";
import { CurrentPrice } from "./CurrentPrice";
import { getFutureShareData, useFutureShareData } from "./useFutureShareData";

const antIcon = <LoadingOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} spin />;

export const FutureGrid = () => {
    const { share_id } = useParams<{ share_id: string }>();

    const mobileDevice = isMobile();
    const [showOpenApp, setShowOpenApp] = useState(false);

    const { data: futureShareResponse, isLoading, error } = useFutureShareData(share_id);

    if (isLoading || !futureShareResponse) {
        return (
            <div>
                <ShareHeader />
                <Wrapper style={{ marginTop: "30%" }}>
                    <Spin indicator={antIcon} />
                </Wrapper>
            </div>
        );
    }

    // NOTE: 错误保护
    if (error) {
        return (
            <div>
                <ShareHeader />
                <WrapperColumn style={{ marginTop: "20px" }}>
                    <img src={bot_order_none_white} style={{ width: 160, height: 160 }} alt={"error"} />
                </WrapperColumn>
            </div>
        );
    }

    // NOTE 和其他分享页同理
    if (showOpenApp) {
        return <OpenAppLoading />;
    }

    const futureShareData = getFutureShareData(futureShareResponse);
    const userInfo = futureShareData.userInfo;
    const buOrder = futureShareData.buOrder;
    const buOrderData = buOrder.bu_order_data;

    const percentProfit = calculateProfitPercent(buOrderData);

    const annualizedProfit = calculateAnnualized({
        createTime: buOrder.create_time,
        percentProfit,
        endTime: buOrder.close_time,
    });

    const priceRange = getPriceRange(buOrder);

    const trend = str(buOrderData.trend);

    const leverage = buOrderData.leverage + "x";

    const profitInfo = getProfitStopInfo(
        buOrderData.profit_stop_type,
        buOrderData.trend,
        buOrderData.profit_stop,
        buOrder.quote,
        buOrderData.profit_stop_delay,
        buOrderData.profit_stop_limit_price,
    );

    const lossInfo = getLossStopInfo(
        buOrderData.loss_stop_type,
        buOrderData.loss_stop,
        buOrder.quote,
        buOrderData.loss_stop_delay,
        buOrderData.trend,
        buOrderData.loss_stop_high,
        buOrderData.loss_stop_limit_price,
        buOrderData.loss_stop_limit_high_price,
    );

    //NOTE 大多数情况下合约base后会有.PERP，加判断保护防止出错
    const noTrailingBase = buOrder.base.endsWith(".PERP") ? buOrder.base.slice(0, -5) : buOrder.base;

    return (
        <div style={!mobileDevice ? { maxWidth: 750, margin: "0 auto" } : {}}>
            <ShareHeader />

            <ShareUserInfo
                nickname={userInfo.nickname}
                avatar={userInfo.avatar}
                symbol={`${noTrailingBase}/${buOrder.quote}`}
                strategyBot="future_grid"
            />

            <Space size={20} />

            <OrderAnnualized
                profitPercent={`${Number(percentProfit) > 0 ? "+" : ""}${percentProfit}%`}
                details={[
                    ["annualized_return", annualizedProfit],
                    ["lasting", buOrder.create_time, buOrder.close_time],
                    ["transactions", buOrder.bu_order_data.exchange_order_paired_count],
                ]}
            />

            <div
                style={{
                    marginTop: 20,
                    marginBottom: 10,
                    alignItems: "flex-start",
                    display: "flex",
                    paddingLeft: 18,
                }}
            >
                <H3>{str("parameters")}</H3>
            </div>

            <RadiusWrapper style={{ paddingBottom: 14 }}>
                {/* <OrderVerParamItem title={str("price_range")} value={priceRange} /> */}

                <OrderVerParamItem
                    title={str("future_init_price")}
                    value={buOrderData.init_price ? `${buOrderData.init_price} ${buOrder.quote}` : str("order_state_not_triggered")}
                />
                {buOrderData.condition !== null && buOrderData.condition_direction !== null && buOrderData.init_price === undefined ? (
                    <OrderVerParamItem title={str("trade_trigger_price")} value={`${buOrderData.condition} ${buOrder.quote}`} />
                ) : null}

                <CurrentPrice base={buOrder.base} quote={buOrder.quote} />

                {/* <OrderVerParamItem title={str("number_of_grids")} value={buOrderData.row} /> */}

                <OrderVerParamItem title={str("future_grid_trend")} value={trend} />

                <OrderVerParamItem title={str("future_grid_leverage")} value={leverage} />

                {/* <OrderVerParamItem title={str("future_grid_profit")} value={profitInfo} />

                <OrderVerParamItem title={str("future_grid_loss")} value={lossInfo} /> */}
            </RadiusWrapper>

            {mobileDevice && <Space size={120} />}

            <CopyButton
                onPress={() => {
                    const redirect = () =>
                        handleFutureGridShareClick({
                            base: buOrder.base,
                            quote: buOrder.quote,
                            shareId: share_id,
                            onPress: () => setShowOpenApp(true),
                        });

                    if (buOrder.status !== "open") {
                        Modal.confirm({
                            title: str("confirm_copy_order_closed_title"),
                            content: str("confirm_copy_order_closed_continue"),
                            onOk() {
                                redirect();
                            },

                            onCancel() {
                                console.log("Cancel");
                            },
                            cancelText: str("button_cancel"),
                            okText: str("subscription_success_confirm"),
                            okButtonProps: {
                                shape: "round",
                                style: { backgroundColor: "#ff7028", borderColor: "#ff7028" },
                            },
                            cancelButtonProps: { shape: "round", className: "cancleBtn" },
                        });
                    } else {
                        redirect();
                    }
                }}
                sticky={mobileDevice}
            />
        </div>
    );
};
