import React from "react";
import Space from "../../components/Space";
import str from "../../i18n/useStr";
import { H4, WrapperColumn } from "../StyleComponent";

const OpenAppLoading = () => {
    return (
        <WrapperColumn>
            <Space size={"20vh"} />
            <img
                src={require("../../images/app_logo.png")}
                style={{ width: "7rem", height: "7rem", maxWidth: "7rem", minHeight: "7rem" }}
                alt={"pionex_app_logo"}
            />
            <Space size={20} />
            <H4>{str("open_app")}</H4>
        </WrapperColumn>
    );
};

export default OpenAppLoading;
