import { ReactNode } from "react";

export const InfoRow = ({ children, cols = 2 }: { children: ReactNode[]; cols?: number }) => {
    return (
        <div
            style={{
                display: "grid",
                gridTemplateRows: "1",
                gridTemplateColumns: `repeat(${cols}, 1fr)`,
                fontSize: "14px",
            }}
        >
            <div style={{ fontWeight: 500, color: "#71737A" }}>{children[0]}</div>
            <div
                style={{
                    gridColumnStart: 2,
                    gridColumnEnd: cols + 1,
                    justifySelf: "end",
                    textAlign: "end",
                    fontWeight: 700,
                    wordBreak: "break-word",
                }}
            >
                {children[1]}
            </div>
        </div>
    );
};
