import str from "src/i18n/useStr";

export const Legend = () => {
    return (
        <div style={{ display: "flex", alignItems: "center", gap: "12px", fontSize: "12px", color: "#A1A2AA" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                <div
                    style={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#000000",
                    }}
                ></div>
                {str("future_current_price")}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                <div
                    style={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#00B070",
                    }}
                ></div>
                {str("copy_futures_open_price")}
            </div>
        </div>
    );
};
