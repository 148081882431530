import axios from "axios";
import Constants from "src/Constants";
import useSWR from "swr";

const postTickerUrl = `${Constants.tickerHost}/get_tickers_index`;

type TickersIndex = {
    [k: string]: number | string;
    price: number;
};

export type TickersIndexResp = {
    code: string;
    message: string;
    data: TickersIndex[];
};

const fetcher = ([url, base, quote]: string[]) =>
    axios
        .post<TickersIndexResp>(url, {
            mbq: [
                {
                    base,
                    quote,
                    market: "pionex.v2",
                },
            ],
        })
        .then((res) => res.data);

export const useTickerIndex = ({ base, quote }: { base: string; quote: string }) => {
    const { data, isLoading, error } = useSWR([postTickerUrl, base, quote], fetcher, {
        refreshInterval: 1000,
        errorRetryCount: 3,
    });

    return {
        data,
        isLoading,
        error,
    };
};
