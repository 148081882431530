import React, { useState } from "react";
import { CoinListDataItem } from "trade_lib_wrapper";
import { H4, H5, WrapperSpaceBetween } from "../../components/StyleComponent/index";
import IconCoinMarket from "../../ShareUIComponentV2/IconCoinMarket";
import "./style.css";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import str from "../../i18n/useStr";
import Space from "../../components/Space";
import { useSymbolCoin } from "trade_quotation_lib";

export interface CoinListProps {
    data: CoinListDataItem[];
}

const Preview_Items_Count = 4;

interface ICoinItem {
    item: CoinListDataItem;
}

function getBase(coin: string) {
    const leverageEnum = ["3S", "2S", "1S", "3L", "2L", "1L"];
    let res = coin;
    leverageEnum.forEach((v) => {
        if (coin.endsWith(v)) {
            res = coin.split(v)[0];
        }
    });
    return res;
}

function CoinItem({ item }: ICoinItem) {
    const symbolInfo = useSymbolCoin(item.coinBase ?? "");
    const coin = symbolInfo?.baseDisplay ?? item.coinBase;
    return (
        <div className="coin-item">
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <IconCoinMarket target={getBase(coin || "")} />
                <H4>{coin}</H4>
            </div>
            <H4>{item.percent}</H4>
        </div>
    );
}

const CoinListV2 = (props: CoinListProps) => {
    const { data } = props;
    const [expand, setExpand] = useState(false);

    const showExpandSet = data.length > Preview_Items_Count;
    const _data = expand ? data : data.slice(0, Preview_Items_Count);
    if (!_data.length) return null;
    return (
        <div className="coin-list-wrapper">
            <WrapperSpaceBetween>
                <H5>{`${str("balance.Coins")} (${str("sr_total_coins", { count: data.length })})`}</H5>
                {showExpandSet && (
                    <WrapperSpaceBetween style={{ hover: "ponter" }} onClick={() => setExpand((v) => !v)}>
                        <H5>{expand ? str("address_seletor_others_close") : str("address_seletor_others_open")}</H5>
                        <H5> {expand ? <UpOutlined /> : <DownOutlined />} </H5>
                    </WrapperSpaceBetween>
                )}
            </WrapperSpaceBetween>
            <Space size={12} />
            <div className="coin-list">
                {_data.map((item) => (
                    <CoinItem item={item} key={item.coinBase} />
                ))}
            </div>
        </div>
    );
};

export default CoinListV2;
