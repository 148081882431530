import str from "src/i18n/useStr";
import { BUOrder, BUOrderData } from "src/use/types";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";

// NOTE 利润率="total_profit"/"quote_investment"
export const calculateProfitPercent = (buOrderData: BUOrderData) => {
    const { total_profit, quote_investment } = buOrderData;

    return new SafeDecimal(total_profit).div(new SafeDecimal(quote_investment)).mul(100).toDecimalPlaces(2).valueOf();
};

const SECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
const SECONDS_IN_A_YEAR = 365 * SECONDS_IN_A_DAY;

// NOTE 年化利润率 - GridV3Robot 中的逻辑 + 清理逻辑
export const calculateAnnualized = ({
    createTime,
    percentProfit,
    endTime,
}: {
    createTime: number;
    percentProfit: string;
    endTime?: number;
}) => {
    const percentNum = Number(percentProfit);

    // avoid edge case
    if (Number.isNaN(percentNum)) {
        return NumberCommonUtils.toPercent(0);
    }

    const now = endTime ? endTime : new Date().getTime();
    const timePast = now - (createTime || 0);

    // NOTE: 同 GridV3 中逻辑， days为 0 则默认改为 1
    const days = timePast / SECONDS_IN_A_DAY || 1;

    let annualized = 0;

    if (percentNum > 0) {
        annualized = percentNum * (SECONDS_IN_A_YEAR / timePast);
    } else {
        //NOTE: 总利润为负时：年化收益率={  (1+总利润率)^(365/天数)-1 }*100
        annualized = (Math.pow(1 + percentNum / 100, 365 / days) - 1) * 100;
    }

    if (annualized > 99999) {
        return "99999%+";
    } else {
        return NumberCommonUtils.toPercent(annualized);
    }
};

export const getPriceRange = (buOrder: BUOrder) => {
    return `${buOrder.bu_order_data.bottom} - ${buOrder.bu_order_data.top} ${buOrder.quote}`;
};

const getDelayInfo = (delay: number | null) => {
    let delayInfo = str("no_delay");

    if (delay) {
        delayInfo = `${str("delay")} ${delay} ${str("grid_order_lasting_second")}`;
    }

    return delayInfo;
};

export const getProfitStopInfo = (
    type: string | null,
    trend: string | null,
    profit: string | null,
    quote: string,
    delay: number | null,
    profitLimit: string | null,
) => {
    if (profit === null) {
        return str("unsetting");
    }

    let profitInfo = "";
    if (type === "price" || type === "price_limit") {
        const lpPrefix = type === "price" ? str("trade_market") : str("trade_limit");
        const lProfit = type === "price" ? profit : profitLimit || profit;
        // NOTE https://pionex.atlassian.net/browse/PW-2055?atlOrigin=eyJpIjoiOWU2YWYxMGU0MTFmNDYyYWJhNzBkZmVlODNiMjZhNWUiLCJwIjoiaiJ9
        if (trend === "no_trend") {
            profitInfo = `${str("profit_arbitrage", {
                rounds: lProfit,
            })}`;
        } else {
            profitInfo = `${lpPrefix} ${lProfit} ${quote}`;
        }
    } else if (type === "profit_amount") {
        profitInfo = `${str("profit_amount")} ${profit} ${quote}`;
    } else if (type === "profit_ratio") {
        profitInfo = `${str("profit_ratio")} ${new SafeDecimal(profit).mul(100).toDecimalPlaces(2).valueOf()} %`;
    }

    const delayInfo = getDelayInfo(delay);

    return `${profitInfo} / ${delayInfo}`;
};

export const getLossStopInfo = (
    type: string | null,
    loss: string | null,
    quote: string,
    delay: number | null,
    trend: string,
    lossStopHigh: number | null,
    lossLimit: string | null,
    lossLimitHigh: string | null,
) => {
    if (loss === null) {
        return str("unsetting");
    }

    let lossInfo = "";
    if (type === "price" || type === "price_limit") {
        const lpPrefix = type === "price" ? str("trade_market") : str("trade_limit");
        const lloss = type === "price" ? loss : lossLimit || loss;
        const llossHigh = type === "price" ? lossStopHigh : lossLimitHigh || loss;

        //NOTE 参看 https://pionex.atlassian.net/wiki/spaces/backend/pages/227016891/API 订单详情部分的 loss_stop_high 字段
        if (trend === "no_trend") {
            lossInfo = `${lpPrefix} ${llossHigh}-${lloss} ${quote}`;
        } else {
            lossInfo = `${lpPrefix} ${lloss} ${quote}`;
        }
    } else if (type === "profit_amount") {
        lossInfo = `${str("loss_amount")} ${loss} ${quote}`;
    } else if (type === "profit_ratio") {
        lossInfo = `${str("loss_ratio")} ${new SafeDecimal(loss).mul(100).toDecimalPlaces(2).valueOf()} %`;
    }

    const delayInfo = getDelayInfo(delay);

    return `${lossInfo} / ${delayInfo}`;
};
