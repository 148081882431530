import { Mix, MixConfig } from "@ant-design/plots";
import moment from "moment";
import { memo, useMemo } from "react";
import str from "src/i18n/useStr";
import { NumberFormatter } from "trade_utils_lib";

interface GridProBalanceSequenceItem {
    balance: string;
    balance_in_usdt: string;
    base_amount: string;
    delta_grid_profit_usdt: string;
    profit: string;
    quote_amount: string;
    timestamp: number;
    total_profit: string;
    total_profit_in_usdt: string;
    transaction: number;
}

function GridEBTotalProfitLine({ totalProfit, lineData }: { totalProfit: number; lineData: GridProBalanceSequenceItem[] }) {
    const minY = useMemo(() => Math.min(...(lineData || []).map(({ total_profit_in_usdt }) => +(total_profit_in_usdt || 0))), [lineData]);
    const config = useMemo<MixConfig>(() => {
        return {
            height: 120,
            padding: [10, 30, 10, 0],
            autoFit: false,
            limitInPlot: false,
            appendPadding: [0, 12, 0, 3],
            region: {
                start: { x: 0, y: 0 },
                end: { x: 1, y: 1 },
            },
            tooltip: {
                shared: true,
                showCrosshairs: true,
                enterable: true,
                crosshairs: {
                    type: "xy",
                    line: {
                        style: {
                            lineWidth: 1,
                            stroke: "#f0ba30",
                        },
                    },
                },
                showTitle: false,
                domStyles: {
                    "g2-tooltip": {
                        boxShadow: "none",
                        minWidth: "180px",
                        backgroundColor: "#858ca3",
                        borderRadius: "5px",
                        padding: "10px",
                    },
                    "g2-tooltip-list": {
                        margin: 0,
                        color: "#ffffff",
                    },
                    "g2-tooltip-list-item": {
                        padding: 0,
                        margin: 0,
                        lineHeight: "18px",
                    },
                    "g2-tooltip-marker": {
                        display: "none",
                    },
                    "g2-tooltip-title": {
                        display: "none",
                    },
                },
                customItems: (originalItems) => {
                    const { timestamp, total_profit_in_usdt } = originalItems[0]?.data;
                    return [
                        {
                            name: str("history_time"),
                            value: moment(timestamp).format("YYYY/MM/DD HH:mm"),
                        },
                        {
                            name: str("grid_transaction_profit"),
                            value: NumberFormatter.format(total_profit_in_usdt),
                        },
                        ...(total_profit_in_usdt
                            ? [
                                  {
                                      name: str("tabBar_arbitrage"),
                                      value: `${NumberFormatter.format(total_profit_in_usdt)} USDT`,
                                  },
                              ]
                            : []),
                    ] as any;
                },
            },
            views: [
                {
                    data: lineData,
                    geometries: [
                        {
                            type: "area",
                            xField: "timestamp",
                            yField: "total_profit_in_usdt",
                            mapping: {
                                style: {
                                    fill: totalProfit >= 0 ? "#00B070" : "#EB4747",
                                    fillOpacity: 0.1,
                                },
                            },
                        },
                        {
                            type: "line",
                            xField: "timestamp",
                            yField: "total_profit_in_usdt",
                            mapping: {
                                style: {
                                    stroke: totalProfit >= 0 ? "#00B070" : "#EB4747",
                                    lineWidth: 1,
                                },
                            },
                        },
                    ],
                    axes: {
                        timestamp: {
                            line: {
                                style: {
                                    opacity: 0,
                                },
                            },
                            tickLine: {
                                style: {
                                    opacity: 0,
                                },
                            },
                            label: {
                                formatter: (val: any) => {
                                    const time: number = Number(val);
                                    return moment(time).format("YYYY/MM/DD HH:mm");
                                },
                                style: {
                                    fill: "#71737a",
                                    fontSize: 12,
                                    textAlign: "start",
                                    textBaseline: "top",
                                },
                                autoRotate: false,
                            },
                            range: [0, 1],
                        },
                        total_profit_in_usdt: {
                            min: minY,
                            grid: {
                                line: {
                                    style: {
                                        opacity: 0,
                                    },
                                },
                            },
                            position: "right",
                            type: "linear",
                            label: {
                                style: {
                                    fontSize: 12,
                                    fill: totalProfit >= 0 ? "#00B070" : "#EB4747",
                                    textAlign: "right",
                                },
                                offset: 60,
                            },
                        },
                    },
                },
            ],
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineData, minY, totalProfit]);

    return <Mix {...config} />;
}
export default memo(GridEBTotalProfitLine);
