import React from "react";
import { H2, H4, WrapperColumn } from "src/components/StyleComponent";
import Constants from "src/Constants";
import str from "src/i18n/useStr";

const DEFAULT_AVATAR = "pionex_app_default.png";

type ShareUserInfoPropsType = {
    nickname?: string;
    avatar?: string;
    strategyBot: string;
    symbol: string;
};

export const ShareUserInfo = ({ nickname, avatar, strategyBot, symbol }: ShareUserInfoPropsType) => {
    const imageSrc = `${Constants.avatarLocationOrigin}${avatar || DEFAULT_AVATAR}`;

    const displayNickName = nickname || str("default_nickname");

    return (
        <WrapperColumn style={{ marginTop: "20px" }}>
            <img
                style={{
                    width: "7.2rem",
                    height: "7.2rem",
                    borderRadius: "50%",
                }}
                src={imageSrc}
                alt={"avatar"}
            />

            <H2 style={{ marginTop: "12px" }}>{str("share_nickname", { nickname: displayNickName })}</H2>

            <H4 style={{ margin: "2px 20px 0" }}>
                {str("share_introduce", {
                    symbol: ` ${symbol} `,
                    strategy_bot: str(strategyBot),
                })}
            </H4>
        </WrapperColumn>
    );
};
