import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const WrapperSpaceBetween = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
`;

export const WrapperColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const H1 = styled.div`
    font-size: 4rem;
    color: #00b070;
    font-weight: 500;
    text-align: center;
`;

export const H2 = styled.div`
    font-size: 2rem;
    color: #292c33;
    font-weight: 500;
    text-align: center;
`;

export const H3 = styled.div`
    font-size: 1.4rem;
    color: #292c33;
    font-weight: 500;
    text-align: center;
    display: block;
`;

export const H4 = styled.div`
    font-size: 1.4rem;
    color: #292c33;
    text-align: center;
`;

export const H5 = styled.div`
    font-size: 1.2rem;
    color: #aeb2bd;
`;

export const H6 = styled.div`
    font-size: 1.1rem;
    color: #aeb2bd;
    text-align: center;
`;

export const RadiusWrapper = styled.div`
    border-radius: 12px;
    background-color: #ffffff;
    margin-left: 12px;
    margin-right: 12px;
`;

export const StickyFooter = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
`;
