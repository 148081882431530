import { ShareOrderDataParams } from "./types";
import { cloneDeep, omit, set } from "lodash";

export const parseOrder = (stringParams: string) => {
    return JSON.parse(stringParams);
};

export const getOrderBase = (symbol: string) => symbol.split("/")[0].replace(/\.perp$/i, "");

export const getOrderQuote = (symbol: string) => symbol.split("/")[1];

export const getUrlSymbol = (symbol: string) => symbol.replace(/\//g, "_");

export const createOrderShareParams = (order: ShareOrderDataParams, shareOrderId: string) => {
    const clonedParams = cloneDeep<ShareOrderDataParams>(order);
    const newClonedParams = omit(clonedParams, "symbol");
    set(newClonedParams, "futuresProShareId", shareOrderId);

    return btoa(JSON.stringify(newClonedParams));
};
