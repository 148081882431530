import * as React from "react";
import { H4, H6, RadiusWrapper, WrapperSpaceBetween } from "../../../components/StyleComponent";
import Space from "../../../components/Space";
import str from "../../../i18n/useStr";
import { SafeDecimal } from "trade_utils_lib";

const DIYParam = (props) => {
    const { priceScaleParam } = props;
    const jsonPriceScaleParam = JSON.parse(priceScaleParam);

    const renderContent = React.useMemo(() => {
        if (Array.isArray(jsonPriceScaleParam.data)) {
            return jsonPriceScaleParam.data.map((item, index) => {
                return (
                    <WrapperSpaceBetween style={{ marginBottom: 14 }} key={index}>
                        <H4 style={{ flex: 1, textAlign: "left" }}>{index + 1}</H4>
                        <H4 style={{ flex: 1, textAlign: "center" }}>{`${new SafeDecimal(item.price_scale).mul(100).toString()}%`}</H4>
                        <H4 style={{ flex: 1, textAlign: "right" }}>{item.shares}</H4>
                    </WrapperSpaceBetween>
                );
            });
        }
    }, [jsonPriceScaleParam.data]);

    return (
        <RadiusWrapper style={{ padding: 16 }}>
            <WrapperSpaceBetween>
                <H6 style={{ flex: 1, textAlign: "left" }}>{"#"}</H6>
                <H6 style={{ flex: 1, textAlign: "center" }}>{str("martingale_price_scale")}</H6>
                <H6 style={{ flex: 1, textAlign: "right" }}>{str("fund_unit")}</H6>
            </WrapperSpaceBetween>
            <Space size={12} />
            {renderContent}
        </RadiusWrapper>
    );
};

export default DIYParam;
