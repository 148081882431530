import Constants from "src/Constants";

type CoinIconProps = {
    coin: string;
    width?: number;
    height?: number;
    alt?: string;
};
export const CoinIcon = ({ coin, width = 28, height = 28, alt = `${coin} icon` }: CoinIconProps) => {
    return (
        <img
            style={{
                width: width,
                height: height,
                borderRadius: "50%",
            }}
            src={`${Constants.mainHost}/res/coin/${coin}_3x.png`}
            alt={alt}
        />
    );
};
