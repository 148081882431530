import moment from "moment";
import React from "react";
import OrderHorParam from "../../components/OrderHorParam";
import Space from "../../components/Space";
import str from "../../i18n/useStr";
import { getFormatTimeLasting } from "../../utils";
import { H1, H3, H5, RadiusWrapper, Wrapper } from "../StyleComponent/index";

const snapshot_timestamp = new Date().getTime();

const getLastDesc = (createTime, closeTime?: any) => {
    const closeOrCurrentTime = !closeTime ? new Date().getTime() : closeTime;
    const timeResult = getFormatTimeLasting(closeOrCurrentTime - createTime);
    return `${timeResult.day}D ${timeResult.hour}H ${timeResult.minute}M`;
};

interface IProps {
    profitTitle: string;
    profitPercent: string;
    details: [string, string | number, (string | number)?][];
}

const OrderAnnualizedV2 = (props: IProps) => {
    const { profitTitle, profitPercent, details } = props;
    const profitColor = Number.parseFloat(profitPercent) < 0 ? "rgb(239, 83, 79)" : "#00B070";

    return (
        <RadiusWrapper>
            <Space size={16} />
            <H3 style={{ color: profitColor }}>{profitTitle}</H3>
            <Space size={4} />
            <H1 style={{ color: profitColor }}>{profitPercent}</H1>
            <Space size={20} />
            <Wrapper style={{ justifyContent: "space-around" }}>
                {details.map(([translateKey, value, closeTime]) => (
                    <OrderHorParam
                        key={translateKey}
                        title={str(translateKey)}
                        value={translateKey === "lasting" ? getLastDesc(value, closeTime) : value}
                    />
                ))}
            </Wrapper>
            <div style={{ borderBottom: "0.5px solid #F2F4F7", margin: "19px 12px 0" }} />
            <div style={{ padding: 12 }}>
                <H5>{str("snapshot_on", { date: moment(snapshot_timestamp).format("YYYY/MM/DD HH:mm:ss") })}</H5>
            </div>
        </RadiusWrapper>
    );
};

export default OrderAnnualizedV2;
