import style from "./style.module.css";
import { isMobile } from "src/utils/index";
import CopyButton from "src/components/CopyButton";
import { InfoRow } from "./components/InfoRow";
import { ShareInfo } from "./components/ShareInfo";
import { PriceChart } from "./PriceChart";
import { CoinIcon } from "./components/CoinIcon";
import { Legend } from "./PriceChart/Legend";
import { TimeRange } from "./PriceChart/TimeRange";
import { useParams } from "react-router-dom";
import { useShareOrderData } from "./useShareOrderData";
import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { InfoPanel } from "./components/InfoPanel";
import { ErrorBoundary } from "./ErrorBoundary";
import { ShareOrderDataParams } from "./types";
import { parseOrder, getOrderBase, getUrlSymbol, createOrderShareParams } from "./utils";
import { OrderSide } from "./components/OrderSide";
import str from "src/i18n/useStr";
import { getInviteCode } from "src/utils/getInviteCode";
import { buildPionexLink } from "trade_utils_lib";
import Constants from "src/Constants";
import logo from "src/images/logo.png";
import { useSymbolInfo } from "trade_quotation_lib";

function CopyFutureMain({ data, shareOrderId }) {
    const mobileDevice = isMobile();
    const order = parseOrder(data?.data.params) as ShareOrderDataParams;

    const base = getOrderBase(order.symbol);
    const lowerBase = base.toLowerCase();
    const side = order.side;

    const symbolInfo = useSymbolInfo(order.symbol);

    const user = data.data.user;
    const orderTime = data.data.created_at * 1000;

    const handleClick = () => {
        const inviteCode = getInviteCode();

        if (mobileDevice) {
            const deepLink = buildPionexLink({
                origin: "pionex://link.pionex.com",
                url: "https://download.pionex.com",
                page: "main",
                share_id: shareOrderId,
                referral: inviteCode || undefined,
                params: {
                    tab: "futures",
                    params: {
                        shareOrderData: {
                            ...order,
                            timestamp: Date.now(),
                            futuresProShareId: shareOrderId,
                        },
                    },
                },
            });

            window.location.href = deepLink;

            setTimeout(() => {
                window.location.href = "https://download.pionex.com";
            }, 50);
        } else {
            const urlSymbol = getUrlSymbol(order.symbol);

            const newSharedOrderParams = createOrderShareParams(order, shareOrderId);

            const url = `${Constants.mainHost}/futures/${urlSymbol}/Manual?share-params=${newSharedOrderParams}${
                inviteCode ? `&r=${inviteCode}` : ""
            }`;
            window.open(url);
        }
    };

    return (
        <div className={style.page}>
            <div className={style.logo}>
                <img style={{ width: "12.805rem", height: "2.571rem" }} src={logo} alt="pionex_logo" />
            </div>

            <div className={style.card}>
                <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    <div style={{ fontSize: "20px", fontWeight: 700, display: "flex", alignItems: "center", gap: "8px" }}>
                        <CoinIcon coin={lowerBase} />
                        {symbolInfo?.baseDisplay}/{symbolInfo?.quoteDisplay} {str("copy_futures_perp")}
                    </div>
                    <InfoRow>
                        <div>{str("future_grid_trend")}</div>
                        <OrderSide side={side} />
                    </InfoRow>
                    <InfoPanel order={order} />
                </div>

                <div className={style["chart-area"]}>
                    <Legend />

                    <div className={style.chart}>
                        <PriceChart order={order} orderTime={orderTime} />
                    </div>

                    <div className={style.legend}>
                        <TimeRange start={orderTime} />
                    </div>
                </div>

                <div className={style.share}>
                    <ShareInfo user={user} orderTime={orderTime} />
                </div>
            </div>

            <CopyButton onPress={handleClick} sticky={mobileDevice} />
        </div>
    );
}

export const CopyFutures = () => {
    const { share_id: shareOrderId } = useParams<{ share_id: string }>();
    const { data, error, isLoading } = useShareOrderData(shareOrderId);

    if (error)
        return (
            <div className={style.page}>
                <CloseCircleOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} />
            </div>
        );

    if (isLoading || !data)
        return (
            <div className={style.page}>
                <LoadingOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} spin />
            </div>
        );

    return <CopyFutureMain data={data} shareOrderId={shareOrderId} />;
};

export const CopyFuturesPage = () => {
    return (
        <ErrorBoundary>
            <CopyFutures />
        </ErrorBoundary>
    );
};
