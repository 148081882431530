import Constants from "src/Constants";
import { ShareOrderData } from "../types";
import { TIME, TIME_RANGE } from "../PriceChart/utils";
import str from "src/i18n/useStr";
import { queryLang } from "src/utils";
import { langEnum } from "src/i18n/types";

const DEFAULT_AVATAR = "pionex_app_default.png";

type ShareInfoProps = {
    user: ShareOrderData["user"];
    orderTime: number;
};

export const ShareInfo = ({ user, orderTime }: ShareInfoProps) => {
    const avatarSrc = `${Constants.avatarLocationOrigin}${user.head_path || DEFAULT_AVATAR}`;

    return (
        <div style={{ borderTop: "1px solid #E5E5E5", paddingTop: "12px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "8px" }}>
                <img
                    style={{
                        width: "28px",
                        height: "28px",
                        borderRadius: "50%",
                    }}
                    src={avatarSrc}
                    alt={"avatar"}
                />
                <div>
                    {user.nickname}
                    {str("copy_futures_share")}
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", color: "#A1A2AA" }}>
                <div>{getTimeDifferenceFromNow(orderTime, Date.now())}</div>
                {/* <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                    <div>1720</div>
                    <div>23</div>
                </div> */}
            </div>
        </div>
    );
};

const translations = {
    year: { "en-US": "year", "zh-CN": "年", "zh-TW": "年" },
    month: { "en-US": "month", "zh-CN": "月", "zh-TW": "月" },
    day: { "en-US": "day", "zh-CN": "天", "zh-TW": "天" },
    hour: { "en-US": "hour", "zh-CN": "小时", "zh-TW": "小時" },
    minute: { "en-US": "minute", "zh-CN": "分钟", "zh-TW": "分鐘" },
    second: { "en-US": "second", "zh-CN": "秒", "zh-TW": "秒" },
    lessThanAMinuteAgo: { "en-US": "Less than a minute ago", "zh-CN": "不到一分钟前", "zh-TW": "不到一分鐘前" },
};

function getTimeDifferenceFromNow(start: number, end: number) {
    const lang = queryLang();

    const unitStrategies = new Map<number, string>([
        [TIME.year, "year"],
        [TIME.month, "month"],
        [TIME.day, "day"],
        [TIME.hour, "hour"],
        [TIME.minute, "minute"],
        [TIME.second, "second"],
    ]);

    const timeDiff = end - start;

    for (let [ms, unit] of unitStrategies.entries()) {
        if (timeDiff >= ms) {
            const diff = Math.floor(timeDiff / ms);
            const unitStr = translations[unit][lang];
            if (lang === langEnum.us) {
                return `${diff} ${unitStr}${diff > 1 ? "s" : ""} ago`;
            } else {
                return `${diff}${unitStr}前`;
            }
        }
    }

    return translations.lessThanAMinuteAgo[lang];
}
