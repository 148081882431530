import str from "src/i18n/useStr";
import style from "./style.module.css";
import { isMobile } from "src/utils";
import QRCode from "qrcode.react";

interface IUserCard {
    data: {
        code?: string;
        title: string;
        followUrl: string;
    };
}

export default function CodeCard(props: IUserCard) {
    const { code, title, followUrl } = props.data;
    const mobileDevice = isMobile();

    return (
        <div className={style.footer}>
            <div>
                <div className={style.kolShareCodeTitle}>{title}</div>
                <div className={style.kolShareCode}>{code}</div>
            </div>
            <div className={style.qrCodeBox}>
                <QRCode value={followUrl} size={mobileDevice ? 46 : 60} level={"H"} includeMargin className="rounded-4px" />
                <div className={style.qrCodeText}>{str("grid_futures_share_link_qr_code")}</div>
            </div>
        </div>
    );
}
