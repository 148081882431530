import React, { memo, useMemo } from "react";
import Constants from "../Constants";

export const IconManager = {
    convertToUriName(name: string): string {
        // eslint-disable-next-line no-empty-character-class
        return name.toLowerCase().replace(/[.]|[+]|[ ]|[/]|[?]|[%]|[#]|[=]|[\\[]|[]]/g, "_");
    },
    getMarketIconUri(market: string) {
        return `${Constants.iconServerHost}/exchange/${IconManager.convertToUriName(market)}_3x.png`;
    },
    getCoinIconUri(coin: string): string {
        return `${Constants.iconServerHost}/coin/${IconManager.convertToUriName(coin)}_3x.png`;
    },
};

export interface IIconCoinOrMarketProps {
    type?: "market" | "coin";
    target: string;
    size?: number | "big" | "small" | "middle";
    style?: any;
    isLeverage?: boolean;
}

// stale
// const sizeMap = {
// 	big: 40,
// 	middle: 30,
// 	small: 20,
// };

const IconCoinMarket = (props: IIconCoinOrMarketProps) => {
    const { type = "coin", target, isLeverage = false } = props;
    // stale
    // const iconSize = sizeMap[size] || size;

    const src = useMemo(() => {
        if (type === "market") {
            return IconManager.getMarketIconUri(target);
        } else {
            const currency = isLeverage ? target.substr(0, target.length - 2) : target;
            return IconManager.getCoinIconUri(currency);
        }
    }, [type, target, isLeverage]);

    return <img src={src} alt={target} style={{ width: "2rem", height: "2rem", marginRight: 6 }} />;
};

export default memo(IconCoinMarket);
