import { useMemo } from "react";
import type { ExchangeGridOrder } from "src/use/types";
import { NumberCommonUtils } from "trade_utils_lib";

export const useAnnualized = (exchangeGridOrder: ExchangeGridOrder, timestamp = 0, profitPercent: string | 0) =>
    useMemo(() => {
        const createTimestamp = exchangeGridOrder.triggerTime || timestamp;

        let percentNum = Number(profitPercent);
        let tempYearProfitPercent = 0;
        let endTime = new Date().getTime();

        if (percentNum > 0) {
            tempYearProfitPercent = percentNum * ((365 * 24 * 60 * 60 * 1000) / (endTime - createTimestamp));
            if (isNaN(tempYearProfitPercent)) {
                tempYearProfitPercent = 0;
            }
        } else {
            let dayResult = (endTime - createTimestamp) / (1000 * 60 * 60 * 24);
            if (dayResult === 0) {
                dayResult = 1;
            }
            tempYearProfitPercent = (Math.pow(1 + percentNum / 100, 365 / dayResult) - 1) * 100;
        }

        if (tempYearProfitPercent > 99999) {
            return "99999%+";
        } else {
            return NumberCommonUtils.toPercent(tempYearProfitPercent);
        }
    }, [exchangeGridOrder, timestamp, profitPercent]);
