import axios from "axios";
import { useState, useEffect } from "react";
import { convertShareDataV2, PlatformType, TemplateV2Info } from "trade_lib_wrapper";
import { ExchangeOrder, OrderShareResp } from "TradeAPILib";
import str from "../../i18n/useStr";
import CONSTANTS from "src/Constants";

export function complementDigit(value, n) {
    if (`${value}`.length >= n) {
        return `${value}`;
    }
    return `${Array(n).join("0")}${value}`.slice(-n);
}

export const getFormatTimeLasting = (timeInterval) => {
    if (timeInterval > 0) {
        let chaTime = timeInterval;
        const day = 1000 * 60 * 60 * 24; // 一天等于毫秒数
        const hour = 1000 * 60 * 60; // 一小时等于毫秒数
        const dayResult = Math.floor(chaTime / day); //

        chaTime = chaTime - dayResult * day; // 减去天的毫秒数。再求小时个数
        const hourResult = Math.floor(chaTime / hour);
        chaTime = chaTime - hourResult * hour; // 减去小时的毫秒数。再求分钟个数
        const minuteResult = Math.floor(chaTime / (1000 * 60));
        const secondResult = Math.floor((chaTime - minuteResult * 1000 * 60) / 1000); // 减去分钟的毫秒数。再求秒的个数
        return {
            day: complementDigit(dayResult, 2),
            hour: complementDigit(hourResult, 2),
            minute: complementDigit(minuteResult, 2),
            second: complementDigit(secondResult, 2),
        };
    } else {
        const defaultZero = complementDigit(0, 2);

        return {
            day: defaultZero,
            hour: defaultZero,
            minute: defaultZero,
            second: defaultZero,
        };
    }
};

export const useShareIdDataV2 = (shareId): [TemplateV2Info[], boolean, string | undefined, OrderShareResp | undefined] => {
    const [data, setData] = useState<TemplateV2Info[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>();
    const [originData, setOriginData] = useState<OrderShareResp>();

    useEffect(() => {
        if (!shareId) {
            setLoading(false);
            setError("share_id_error");
        }

        axios
            .get(`${CONSTANTS.tradeServiceHost}/orders/order_share/${shareId}`, {
                timeout: 10000,
            })
            .then((res) => {
                setData(convertShareDataV2(PlatformType.web, res.data.data, str));
                setOriginData(res.data.data);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [shareId]);

    return [data, loading, error, originData];
};
