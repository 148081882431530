const strings = {
    confirm_copy_order_closed_title: "Bot wurde beendet",
    confirm_copy_order_closed_continue:
        "Der Trader hat den Bot geschlossen. Sind Sie sicher, dass Sie der Bestellung weiterhin folgen möchten?",
    button_cancel: "Stornieren",
    subscription_success_confirm: "zum kopieren",
    futures_grid_v2_share_title: "Futures Grid Bot von $name$",
    trade_trigger_price: "Trigger-Preis",
    future_init_price: "Einstiegspreis",
    order_state_not_triggered: "Nicht ausgelöst",
    future_current_price: "Aktueller Preis",
    grid_position_share_ratio_percent: "Gewinnbeteiligung",
    futures_grid_v2_total_profit: "Aktuell PnL",
    futures_grid_v2_grid_profit_apr: "Grid jährl",
    lasting: "Laufzeit",
    futures_grid_v2_order_create_time: "Erstellungszeit",
    futures_grid_v2_create_order: "jetzt kopieren",
    grid_futures_invited_only_tip: "Diesen Bot können nur eingeladene Benutzer von $creator$ folgen",
    futures_grid_v2_share_code: "Ref-Link von $name$",
    grid_futures_share_link_qr_code: "Scannen zum kopieren",
    grid_futures_order_closed: "Der trader hat den Bot gestoppt.",
};

export default strings;
