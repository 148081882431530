import { KlineIntervalRest, toKlineInterval, KlineData } from "trade_quotation_lib";
import chart from "chart.js";

// TODO comment 没有 second 级别的 k 线数据，为了保证至少有 3 个以上的点成图，3 分钟以下的都算做是秒级的

export type TIME_RANGE = "year" | "month" | "day" | "hour" | "minute" | "second";

// millisecond as the base
export const TIME = {
    year: 1000 * 60 * 60 * 24 * 365,
    month: 1000 * 60 * 60 * 24 * 30,
    day: 1000 * 60 * 60 * 24,
    hour: 1000 * 60 * 60,
    minute: 1000 * 60,
    second: 1000,
};

const THREE_MINUTES = TIME.minute * 3;

/*---------------------------time range for fetching kline data---------------------------*/
function getTimeDifferenceFromNow(start: number, end: number) {
    const unitStrategies = new Map<number, TIME_RANGE>([
        [TIME.year, "year"],
        [TIME.month, "month"],
        [TIME.day, "day"],
        [TIME.hour, "hour"],
        [THREE_MINUTES, "minute"],
    ]);

    const timeDiff = end - start;

    let flag: TIME_RANGE = "second";
    for (let [ms, unit] of unitStrategies.entries()) {
        if (timeDiff >= ms) {
            flag = unit;
            break;
        }
    }

    return flag;
}

// TODO comment 不同的时间级别对应的不同的 K 线的 interval
const TIME_INTERVAL: Record<TIME_RANGE, KlineIntervalRest> = {
    year: "30d",
    month: "1d",
    day: "1h",
    hour: "5m",
    minute: "1m",
    second: "1m",
};

export function getKlineIntervalParam(start: number, end: number) {
    const diff = getTimeDifferenceFromNow(start, end);
    const klineRestInterval = TIME_INTERVAL[diff];

    return toKlineInterval(klineRestInterval, "rest");
}

export function getFetchStartTimeAccTimeInterval(start: number, end: number) {
    const diff = getTimeDifferenceFromNow(start, end);
    const interval = end - start;

    if (diff === "second") {
        return start - TIME.minute * 3;
    }

    // 扩大一倍的展示范围
    return start - interval;
}

/*-------------------------kline data transformation-----------------------------*/

type ChartDataType = {
    x: number;
    y: number;
};
export function transformKlineData(responseData?: KlineData) {
    const historyData = responseData?.data;

    if (!historyData) return [];

    return historyData.map(({ time, close }) => {
        return {
            x: time,
            y: close,
        };
    });
}

export function getLastValue(data: ChartDataType[]) {
    return data[data.length - 1]?.y;
}

/*--------------------------开仓价格是否位于 data set 的 y 的范围内----------------------------*/

const getChartSetRange = (chartData: { x: number; y: number }[]) => {
    return chartData.reduce(
        (prev, cur) => {
            if (cur.y < prev.min)
                return {
                    ...prev,
                    min: cur.y,
                };
            if (cur.y > prev.max) {
                return {
                    ...prev,
                    max: cur.y,
                };
            }

            return prev;
        },
        {
            min: Infinity,
            max: -Infinity,
        },
    );
};

export const isPriceInTheRange = (chartData: { x: number; y: number }[], price?: number) => {
    if (price === undefined || isNaN(price)) return false;

    const { min, max } = getChartSetRange(chartData);
    if (price >= min && price <= max) return true;
    return false;
};

export const getAnnotations = (chartData: { x: number; y: number }[], lastValue: number, price?: string) => {
    const numPrice = Number(price);

    const baseAnnotation = [
        {
            type: "line",
            mode: "horizontal",
            scaleID: "y-axis-0",
            value: lastValue,
            borderColor: "#D9D9D9",
            borderWidth: 2,
            label: {
                enabled: true,
                position: "left",
                fontColor: "#000000",
                backgroundColor: "#D9D9D9",
                content: lastValue,
                fontStyle: 400,
            },
            borderDash: [5, 5],
        },
    ];

    if (isPriceInTheRange(chartData, numPrice)) {
        return [
            ...baseAnnotation,
            {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: numPrice,
                borderColor: "#00B070",
                borderWidth: 2,
                label: {
                    enabled: true,
                    position: "right",
                    fontColor: "#00B070",
                    backgroundColor: "#C8EBDE",
                    content: numPrice,
                    fontStyle: 400,
                    // xAdjust: -30,
                },
                borderDash: [2, 2],
            },
        ];
    }

    return baseAnnotation;
};

/*--------------------------get the buffer space on y axis----------------------------*/
export const getBufferSpace = (chartData: { x: number; y: number }[]) => {
    const { min, max } = getChartSetRange(chartData);

    return { min: min * 0.98, max: max * 1.02 };
};

/*--------------------------calculate pixels according to data set----------------------------*/
export function getPixelHeight(chartInstance: chart, dataValue: number, datasetIndex = 0) {
    // 获取元数据和第一个数据点的_yScale对象
    const metaData = chartInstance.getDatasetMeta(datasetIndex);
    const firstData = metaData.data[0];

    // TODO check why types are different with the data
    const yScale: any = firstData._yScale;

    // 用于将数据值映射到像素位置
    const percent = (dataValue - yScale.min) / (yScale.max - yScale.min);
    const pixel = (yScale._endPixel - yScale._startPixel) * percent + yScale._startPixel;

    return pixel;
}

// const pixelHeight = getPixelHeight(chartInstance, 0, 60000);
// console.log(`The pixel height for the value 60000 is: ${pixelHeight}`);
