import { useParams } from "react-router-dom";
import { TOrderDetails, useShareRatioData } from "./useShareRatioData";
import style from "./style.module.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { gotoPionexApp, isMobile, queryLang } from "src/utils";
import Constants from "src/Constants";
import { DEFAULT_AVATAR } from "src/components/ShareUserInfo";
import str from "src/i18n/useStr";
import { useTickerIndex } from "../FutureGrid/CurrentPrice/useTickerIndex";
import { calculateAnnualized, calculateProfitPercent } from "../FutureGrid/calculation";
import { NumberFormatter, SafeDecimal } from "trade_utils_lib";
import { getLastDesc } from "src/components/OrderAnnualized";
import moment from "moment";
import { Wrapper } from "src/components/StyleComponent";
import { Modal, Spin } from "antd";
// eslint-disable-next-line import/namespace
import { LoadingOutlined } from "@ant-design/icons";
import QrCode from "qrcode.react";
import { ExchangeOrderType, TradeUtils } from "TradeAPILib";
import { report } from "src/utils/report";
import { obj2StrParams } from "./utils";
import { useSymbol } from "trade_quotation_lib";

export const FutureGridV2View = ({ data, share_id }: { data: TOrderDetails["data"]; share_id: string }) => {
    const { bu_order_data, base, quote } = data?.order_data?.payload || {};

    const exchangeOrder = useMemo(() => {
        const condition = data?.order_data?.payload?.bu_order_data?.condition;
        return TradeUtils.convertToExchangeOrder({
            ...data?.order_data?.payload,
            bu_order_type: data?.bu_order_type,
            bu_order_id: data?.bu_order_id,
            bu_order_data: {
                ...data?.order_data?.payload?.bu_order_data,
                create_time: data?.order_create_time,
                close_time: data?.order_end_time,
                condition: new SafeDecimal(condition).eq(0) ? null : condition,
            },
        });
    }, [data?.bu_order_id, data?.bu_order_type, data?.order_create_time, data?.order_data?.payload, data?.order_end_time]);
    const order = exchangeOrder ? TradeUtils.getInnerOrder(exchangeOrder) : null;

    const mobileDevice = isMobile();
    const symbolInfo = useSymbol(base, quote);
    const baseDisplay = symbolInfo?.baseDisplay ?? "";
    const quoteDisplay = symbolInfo?.quoteDisplay ?? "";
    const isFutures = exchangeOrder?.orderType === ExchangeOrderType.gridV4Futures;
    const info = useMemo(() => {
        const trendText = str(bu_order_data?.trend || "long");
        const leverageText = data?.leverage + "x";
        const arr = isFutures ? [`${baseDisplay}/${quoteDisplay}`, leverageText, trendText] : [`${baseDisplay}/${quoteDisplay}`];
        const brr: React.ReactNode[] = [];
        arr.forEach((i, index) => {
            if (index !== 0) {
                brr.push(<div className={style.botInfoSplit} key={`${i}_${index}`} />);
            }
            return brr.push(<div key={i}>{i}</div>);
        });
        return brr;
    }, [baseDisplay, quoteDisplay, bu_order_data?.trend, data?.leverage, isFutures]);

    useEffect(() => {
        if (isFutures) {
            report("futures_gridbot_share_visit", { share_id });
        } else {
            report("spot_gridbot_share_visit", { share_id });
        }
    }, [share_id, isFutures]);

    // 当前价格获取
    const { data: currentPriceResp } = useTickerIndex({
        base: base ?? "",
        quote: quote ?? "",
    });
    const currentPrice = useMemo(() => {
        return currentPriceResp?.data?.[0]?.price ?? "";
    }, [currentPriceResp]);

    // 收益率
    const percentProfit = useMemo(() => {
        return calculateProfitPercent({
            total_profit: bu_order_data?.total_profit,
            quote_investment: order?.totalCost,
        } as any);
        // const annualizedProfit = calculateAnnualized(data?.order_create_time ?? 0, percentProfit);
    }, [order?.totalCost, bu_order_data?.total_profit]);

    // 网格年化
    const gridProfit = useMemo(() => {
        const p = new SafeDecimal(bu_order_data?.grid_profit)
            .div(new SafeDecimal(order?.totalCost))
            .mul(100)
            .valueOf();

        const n = calculateAnnualized({
            createTime: data?.order_create_time ?? 0,
            percentProfit: p,
            endTime: data?.order_end_time,
        });
        return `${n}`.replace(/[^0-9\.-]/gi, "");
    }, [bu_order_data?.grid_profit, order?.totalCost, data?.order_create_time, data?.order_end_time]);

    const qrUrl = `${Constants.shareHost}${window.location.pathname}`;
    const shareCode = data.extra_data?.share_code || data.share_code || undefined;
    const isPrivate = !!data.extra_data?.is_private;

    const [submitting, setSubmittingState] = useState(false);
    const onCreateOrder = useCallback(() => {
        const urlParams = isFutures
            ? {
                  share_ratio_id: share_id,
                  referral: shareCode,
              }
            : {
                  spot_share_id: share_id,
                  referral: shareCode,
              };

        const webUrl = isFutures
            ? `${Constants.mainHost}/futures/${base}_${quote}/Bot${obj2StrParams(urlParams)}`
            : `${Constants.mainHost}/trade/${base}_${quote}/Bot${obj2StrParams(urlParams)}`;

        const lang = new URLSearchParams(window.location.search).get("l") || "zh-TW";
        const mUrl = `https://download.pionex.com/?lang=${lang}`;
        if (isFutures) {
            report("futures_gridbot_share_follow", { share_id });
        } else {
            report("spot_gridbot_share_follow", { share_id });
        }
        setSubmittingState(true);
        setTimeout(() => setSubmittingState(false), 3000);
        gotoPionexApp({
            url: webUrl,
            mUrl: mUrl,
            page: "TRADE.bot.botOrderList",
            ...urlParams,
        });
    }, [shareCode, isFutures, base, quote, share_id]);

    const onCreateOrderProxy = useCallback(() => {
        if (submitting) {
            return;
        }
        if (exchangeOrder?.isRunning) {
            onCreateOrder();
        } else {
            Modal.confirm({
                title: str("confirm_copy_order_closed_title"),
                content: str("confirm_copy_order_closed_continue"),
                onOk() {
                    onCreateOrder();
                },

                onCancel() {
                    console.log("Cancel");
                },
                cancelText: str("button_cancel"),
                okText: str("subscription_success_confirm"),
                okButtonProps: {
                    style: { backgroundColor: "#ff7028", borderColor: "#ff7028" },
                },
                cancelButtonProps: { className: "cancleBtn" },
            });
        }
    }, [exchangeOrder?.isRunning, onCreateOrder, submitting]);

    const changePosition = new SafeDecimal(percentProfit).lessThan(gridProfit);
    const [avatar, setAvatar] = useState("");
    useEffect(() => {
        const img = new Image();
        img.onload = function () {
            setAvatar(img.src);
        };
        img.onerror = function () {
            setAvatar(`${Constants.avatarLocationOrigin}${DEFAULT_AVATAR}`);
        };
        img.src = `${Constants.avatarLocationOrigin}${data?.avatar}`;
    }, [data?.avatar]);

    const $num = useCallback((n: string | number, precision?: number) => {
        return NumberFormatter.format(n, { locale: queryLang(), precision });
    }, []);
    const addPlus = useCallback((n: string) => {
        return !n.startsWith("-") ? "+" : "";
    }, []);

    return (
        <div className={!mobileDevice ? style.normalContent : style.mobileContent}>
            <div className={style.logoBox}>
                <img className={style.logoIcon} src={require("../../images/logo_white.png")} alt="" />
            </div>
            {!exchangeOrder?.isRunning && (
                <div className={style.orderClosedTipsBox}>
                    <img className={style.orderClosedTipsIcon} src={require("../../images/tips.png")} alt="" />
                    <div className={style.orderClosedTipsText}>{str("grid_futures_order_closed")}</div>
                </div>
            )}
            <div className={style.infoCard}>
                <div className={style.infoCardHeader}>
                    {avatar ? <img className={style.kolAvatar} src={avatar} alt="avatar" /> : <div className={style.kolAvatar} />}
                    <div className={style.botInfo}>
                        {isFutures ? (
                            <div className={style.botInfoTitle}>{str("futures_grid_v2_share_title", { name: data?.nick_name ?? "" })}</div>
                        ) : (
                            <div className={style.botInfoTitle}>{str("spot_grid_v2_share_title", { name: data?.nick_name ?? "" })}</div>
                        )}
                        <div className={style.botInfoInner}>{info}</div>
                    </div>
                </div>
                <div className={style.infoCardSplit}>
                    <div className={style.infoCardSplitLine}></div>
                </div>
                <div className={style.infoCardFooter}>
                    {order?.isConditionAndNotTriggered && order.condition && (
                        <div className={style.row}>
                            <div className={style.infoLabel}>{str("trade_trigger_price")}</div>
                            <div className={style.infoValue}>{`${order.condition} ${quoteDisplay}`}</div>
                        </div>
                    )}
                    <div className={style.row}>
                        <div className={style.infoLabel}>{str("future_init_price")}</div>
                        {order?.isConditionAndNotTriggered ? (
                            <div className={style.infoValue}>{str("order_state_not_triggered")}</div>
                        ) : (
                            <div className={style.infoValue}>{`${
                                bu_order_data?.init_price
                                    ? $num(bu_order_data.init_price, new SafeDecimal(bu_order_data.init_price).decimalPlaces())
                                    : "--"
                            } ${quoteDisplay}`}</div>
                        )}
                    </div>
                    <div className={style.row}>
                        <div className={style.infoLabel}>{str("future_current_price")}</div>
                        <div className={style.infoValue}>{`${
                            currentPrice ? $num(currentPrice, new SafeDecimal(currentPrice).decimalPlaces()) : "--"
                        } ${quoteDisplay}`}</div>
                    </div>
                    {data.shared_ration && new SafeDecimal(data.shared_ration).greaterThan(0) ? (
                        <div className={style.row}>
                            <div className={style.infoLabel}>{str("grid_position_share_ratio_percent")}</div>
                            <div className={style.infoValue}>{`${new SafeDecimal(data.shared_ration).mul(100).toString()}%`}</div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className={style.profitBox}>
                {!changePosition ? (
                    <div className={style.gridProfitBox}>
                        <div className={style.gridProfitBoxTitle}>{str("futures_grid_v2_total_profit")}</div>
                        <div className={style.gridProfitBoxValue} style={{ color: !percentProfit.startsWith("-") ? "#0ABF7F" : "#F04848" }}>
                            {`${addPlus(percentProfit)}${$num(percentProfit)}%`}
                        </div>
                    </div>
                ) : (
                    <div className={style.gridProfitBox}>
                        <div className={style.gridProfitBoxTitle}>{str("futures_grid_v2_grid_profit_apr")}</div>
                        <div className={style.gridProfitBoxValue} style={{ color: !gridProfit.startsWith("-") ? "#0ABF7F" : "#F04848" }}>
                            {`${addPlus(gridProfit)}${$num(gridProfit)}%`}
                        </div>
                    </div>
                )}
                {!mobileDevice ? (
                    <div className={style.profitBoxBottom}>
                        {changePosition ? (
                            <div>
                                <div>{str("futures_grid_v2_total_profit")}</div>
                                <div>{`${addPlus(percentProfit)}${$num(percentProfit)}%`}</div>
                            </div>
                        ) : (
                            <div>
                                <div>{str("futures_grid_v2_grid_profit_apr")}</div>
                                <div>{`${addPlus(gridProfit)}${$num(gridProfit)}%`}</div>
                            </div>
                        )}
                        <div>
                            <div>{str("lasting")}</div>
                            <div>{getLastDesc(data?.order_create_time, data?.order_end_time)}</div>
                        </div>
                        <div>
                            <div>{str("futures_grid_v2_order_create_time")}</div>
                            <div>{moment(data?.order_create_time).format("YYYY/MM/DD HH:mm")}</div>
                        </div>
                    </div>
                ) : (
                    <div className={style.profitBoxBottomMobile}>
                        <div>{`${str("lasting")}: ${getLastDesc(data?.order_create_time, data?.order_end_time)}`}</div>
                        {changePosition ? (
                            <div>{`${str("futures_grid_v2_total_profit")}: ${$num(percentProfit)}%`}</div>
                        ) : (
                            <div>{`${str("futures_grid_v2_grid_profit_apr")}: ${$num(gridProfit)}%`}</div>
                        )}
                        <div>{`${str("futures_grid_v2_order_create_time")}: ${moment(data?.order_create_time).format(
                            "YYYY/MM/DD HH:mm",
                        )}`}</div>
                    </div>
                )}
            </div>
            <div
                className={`${style.createOrderBtnBase} ${exchangeOrder?.isRunning ? style.createOrderBtn : style.createOrderBtnDisable}`}
                onClick={onCreateOrderProxy}
            >
                {submitting ? <img src={require("../../images/icon_loading_white.png")} className={style.loadingIcon} /> : null}
                {str("futures_grid_v2_create_order")}
            </div>
            {isPrivate && <div className={style.privateTip}>{str("grid_futures_invited_only_tip", { creator: data?.nick_name })}</div>}
            <div className={style.footer}>
                <div>
                    <div className={style.kolShareCodeTitle}>{str("futures_grid_v2_share_code", { name: data?.nick_name })}</div>
                    <div className={style.kolShareCode}>{shareCode}</div>
                </div>
                <div className={style.qrCodeBox}>
                    <QrCode value={qrUrl} size={mobileDevice ? 46 : 60} level={"H"} includeMargin className="rounded-4px" />
                    <div className={style.qrCodeText}>{str("grid_futures_share_link_qr_code")}</div>
                </div>
            </div>
        </div>
    );
};

export const FutureGridV2 = () => {
    const { share_id } = useParams<{ share_id: string }>();
    const { data, error, isLoading } = useShareRatioData({ id: share_id });
    const mobileDevice = isMobile();

    useEffect(() => {
        document.body.classList.add(style.page);
        document.documentElement.classList.add("theme-dark");
        return () => {
            document.body.classList.remove(style.page);
            document.documentElement.classList.remove("theme-dark");
        };
    }, []);

    if (isLoading || !data) {
        return (
            <Wrapper style={{ marginTop: mobileDevice ? "50%" : "10%" }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} spin />} />
            </Wrapper>
        );
    }
    return <FutureGridV2View data={data} share_id={share_id} />;
};
