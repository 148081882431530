/**
 * 将对象转换为?a=5&b=7形式
 * @param obj
 * @param firstStr
 * @returns {string|string}
 */
export function obj2StrParams(obj, firstStr = "?") {
    let params = firstStr;

    for (const p in obj) {
        params += p + "=" + obj[p] + "&";
    }
    return params === firstStr ? "" : params.substring(0, params.length - 1);
}
