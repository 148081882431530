const isDev = () => process.env.NODE_ENV === "development";

const isOnDevEnv = () => /\.pionexdev\.com$/.test(window.location.host);

export const isBeta = isDev() || isOnDevEnv();

const CONSTANTS = {
    tradeServiceHost: "/strategy/v1.8",
    tickerHost: "/portfolio",
    signalHost: "/signal/api/v1",
    kolFHost: "/kol-apis/fapi/v1",
    kolTApi: "/kol-apis/tapi/v1",
    shareApi: "/apis/share/order",
    iconServerHost: !isBeta ? "https://www.pionex.com/res" : "https://www.pionexdev.com/res",
    avatarLocationOrigin: !isBeta ? "https://avatar.pionex.com/" : "https://avatar.pionexdev.com/",
    mainHost: !isBeta ? "https://www.pionex.com" : "https://www.pionexdev.com",
    shareHost: !isBeta ? "https://share.pionex.com" : "https://share.pionexdev.com",
};

export default CONSTANTS;
