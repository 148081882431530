const strings = {
    martingale_bot: "馬丁格爾機器人",
    mtg_multi_order_name: "多幣馬丁機器人",
    grid_v3_bot: "網格機器人",
    annualized_return: "年化收益率",
    lasting: "運行時長",
    grid_transaction: "已完成輪次",
    parameters: "訂單參數",
    fund_unit: "份",
    grid_order_lasting_day: "天",
    grid_order_lasting_hour: "小時",
    grid_order_lasting_minute: "分鐘",
    grid_order_lasting_second: "秒",
    martingale_take_profit_ratio: "賺多少止盈",
    martingale_price_range: "價格區間",
    martingale_safety_orders: "最大加倉次數",
    martingale_price_scale: "跌多少加倉",
    martingale_order_volume_scale: "加倉倍投倍數",
    trade_trigger_price: "觸發價格",
    martingale_take_profit_percentage: "止盈百分比",
    martingale_take_profit_trailing_percentage: "回落多少後賣出",
    martingale_price_scale_trailing_percentage: "反彈多少後買入",
    martingale_DIY_shares: "買入份數",
    martingale_DIY_trailing: "最大反彈",
    trade_signal: "訊號",
    martingale_signal_immediately: "立即啟動",
    martingale_signal_time_lapse: "延時啟動",
    martingale_signal_time_lapse_with_unit: "延時啟動($time$秒)",
    copy_bot_list_nnt: "跟單人數",
    copy_bot_list_amount: "跟單金額",
    copy_bot_list_bot_lasting_roi: "創建以來收益率",
    quotation_mark: "「$content$」",
    copy_feed_view_all: "查看詳情",

    // new
    share_introduce: "這是我的$symbol$$strategy_bot$", // eg: 這是我的BTC/USDT馬丁格爾機器人
    share_nickname: "我是 $nickname$", // eg:  我是Haze
    profit_rate: "利潤率",
    snapshot_on: "採用$date$時的盈利數據",
    martingale_rounds: "輪次",
    copy_in_one_click: "一鍵跟單",
    share_order_closed: "該訂單已經關閉",
    martingale_stop_loss_percentage: "止損百分比（總利潤）",
    unrealized_stop_loss_percentage: "止損百分比（浮動盈虧）",
    open_app: "正在打開派網APP...",
    number_of_grids: "網格數",
    profit_rate_per_grid: "每格利潤率",
    take_profit_price: "止盈價格",
    stop_loss_price: "止損價格",
    price_range: "價格區間",
    transactions: "套利次數",

    default_nickname: "派網用戶",

    sr_rules_modify_by_time_interval_none: "暫停平衡",
    sr_rules_modify_title: "平衡條件",
    share_sr_enter_alert_trigger_threshold_desc: "佔比變化超過", // eg: 佔比變化超過 10%
    rebalanced_bot: "屯幣寶",

    button_cancel: "取消",
    subscription_success_confirm: "去跟單",
    confirm_copy_order_closed_title: "該機器人已關閉",
    confirm_copy_order_closed_continue: "分享者已經關閉該機器人，您確定想要繼續跟單嗎？",
    base_profit_percent: "$coin$收益率",

    future_grid: "合約網格",
    future_init_price: "開單價格",
    future_current_price: "當前價格",
    future_grid_trend: "方向",
    future_grid_leverage: "槓桿倍數",
    future_grid_profit: "止盈設定",
    future_grid_loss: "止損設定",

    long: "做多",
    short: "做空",
    no_trend: "中性",

    profit_price: "止盈價格",
    profit_amount: "盈利額",
    profit_ratio: "盈利比率",
    profit_arbitrage: "無持倉套利$rounds$次",

    loss_price: "止損價格",
    loss_amount: "虧損額",
    loss_ratio: "虧損比率",

    unsetting: "未設定",
    no_delay: "無延遲",
    delay: "延遲",
    order_state_not_triggered: "未觸發",
    running_total_profit: "總利潤",
    amount_pnl_rate: "累計收益率",
    capital_scale: "資金規模",
    number_of_buyers: "購買人數",
    accumulated_arbitrage_rounds: "累計套利次數",
    count: "次",
    please_open_this_link: "請將此連結在已安裝Pionex App的行動裝置中啟用。",
    history_time: "時間",
    grid_transaction_profit: "收益",
    tabBar_arbitrage: "套利",
    trade_limit: "限價",
    trade_market: "市價",

    //SR
    strateg_Min: "分鍾",
    strateg_Hour: "小時",
    sr_switch_momentum: "動量輪動",
    sr_switch_momentum_period: "輪動周期",
    sr_switch_momentum_ratio: "輪動比例",
    sr_switch_mode_type: "調倉方式",
    sr_switch_momentum_period_desc: "每$time$輪動一次",
    sr_switch_momentum_ratio_desc: "根據所選幣種的漲幅，把倉位比例調整為：$percents$",
    sr_enter_alert_trigger_threshold_desc: "佔比變化超過$percent$",
    sr_rebalance_strategy_mode: "每$value$再平衡一次",
    address_seletor_others_open: "展開",
    address_seletor_others_close: "收起",
    "balance.Coins": "幣種",
    sr_total_coins: "共有$count$個幣種",
    sr_switch_momentum_method: "輪動方式",
    sr_switch_momentum_mode: "獲利才輪動",
    sr_switch_momentum_roi: "輪動收益率",
    sr_switch_momentum_sub_type_periodic: "時間",
    sr_switch_momentum_sub_type_threshold: "收益率",
    switch_on: "啟用",
    switch_off: "關閉",
    sr_switch_momentum_mode_roi: "開始轮动收益率",
    grid_share_sr_rebalance_new_count: "調倉次數",
    not_supported_type: "當前的訂單類型不支持",
    other_type: "其它類型",
    no_data: "無數據",
    copy_futures_perp: "永續",
    copy_futures_lower_limit: "區間下限價格",
    copy_futures_upper_limit: "區間上限價格",
    copy_futures_grid_mode: "網格模式",
    copy_futures_grids_arithmetic: "等差",
    copy_futures_grids_geometric: "等比",
    copy_futures_grids: "格",
    copy_futures_open_price: "開倉價格",
    copy_futures_share: "分享了一個合約交易跟單",
    copy_futures_open: "開倉",
    copy_futures_mark_price_triggered: "標記價格觸發",
    copy_futures_latest_price_triggered: "最新價格觸發",

    futures_grid_v2_share_title: "$name$ 的合約網格機器人",
    futures_grid_v2_order_create_time: "開單時間",
    futures_grid_v2_total_profit: "當前收益率",
    futures_grid_v2_grid_profit_apr: "網格套利年化",
    futures_grid_v2_create_order: "立即跟單",
    futures_grid_v2_share_code: "$name$ 的邀請碼",
    futures_grid_v2_qr_code: "掃QR碼跟單",
    download_app_text: "下載派網APP",
    grid_position_share_ratio_percent: "分潤比例",
    grid_futures_share_link_qr_code: "掃碼立即跟單",

    trade_total_profit_rate: "總利潤率",
    grid_spl_set_delay_exist: "（延時$delay$秒）",
    grid_futures_invited_only_tip: "該訂單僅限$creator$的邀請用戶跟單",
    grid_futures_order_closed: "分享者已將當前訂單關閉",
    spot_grid_v2_share_title: "$name$ 的現貨網格",

    order_start_market_price: "開單時BTC價格",
    current_market_price: "當前BTC價格",
    sr_coins: "$coins$...等$count$個幣種",
    sr_title: "$name$ 的屯幣寶",
};

export default strings;
