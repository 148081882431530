const strings = {
    martingale_bot: "DCA bot(Martingale)",
    mtg_multi_order_name: "Composite DCA Bot",
    grid_v3_bot: "grid bot",
    annualized_return: "Annualized",
    lasting: "Lasting",
    grid_transaction: "Rounds",
    parameters: "Parameters",
    fund_unit: "shares",
    grid_order_lasting_day: "D",
    grid_order_lasting_hour: "H",
    grid_order_lasting_minute: "m",
    grid_order_lasting_second: "s",
    martingale_take_profit_ratio: "Take profit ratio",
    martingale_price_range: "Price range",
    martingale_safety_orders: "Safety orders",
    martingale_price_scale: "Price deviation",
    martingale_order_volume_scale: "Volume scale",
    trade_trigger_price: "Trigger Price",
    martingale_take_profit_percentage: "Take profit percentage",
    martingale_take_profit_trailing_percentage: "Trailing percent",
    martingale_price_scale_trailing_percentage: "Max rebounce",
    martingale_DIY_shares: "shares",
    martingale_DIY_trailing: "Trailing",
    trade_signal: "Signal",
    martingale_signal_immediately: "Immediately",
    martingale_signal_time_lapse: "Time-lapse",
    martingale_signal_time_lapse_with_unit: "Time-lapse($time$s)",
    copy_bot_list_nnt: "Copiers",
    copy_bot_list_amount: "AUM",
    copy_bot_list_bot_lasting_roi: "ROI since created",
    quotation_mark: "“$content$”",
    copy_feed_view_all: "Check detail",

    // new
    share_introduce: "This is my $symbol$$strategy_bot$", // eg: 这是我的BTC/USDT马丁格尔机器人
    share_nickname: "This is $nickname$", // eg:  我是Haze
    profit_rate: "Profit rate",
    snapshot_on: "Snapshot on $date$",
    martingale_rounds: "Rounds",
    copy_in_one_click: "Copy in one click",
    share_order_closed: "The bot has been stopped.",
    martingale_stop_loss_percentage: "Stop loss percentage(Total profit)",
    unrealized_stop_loss_percentage: "Stop loss percentage(Unrealized profit)",
    open_app: "Opening Pionex...",
    number_of_grids: "Grids",
    profit_rate_per_grid: "Profit/grid",
    take_profit_price: "Take profit price",
    stop_loss_price: "Stop loss price",
    price_range: "Price range",
    transactions: "Txs",

    default_nickname: "Pionex",

    sr_rules_modify_by_time_interval_none: "Pause rebalancing",
    sr_rules_modify_title: "Rebalance mode",
    share_sr_enter_alert_trigger_threshold_desc: "Deviated", // eg: 佔比變化超過 10%
    rebalanced_bot: "Rebalancing Bot",

    button_cancel: "Cancel",
    subscription_success_confirm: "Start copying",
    confirm_copy_order_closed_title: "The bot is stopped",
    confirm_copy_order_closed_continue: "The trader has stopped the bot，are you sure you want to copy the bot？",
    base_profit_percent: "Profit in $coin$",

    future_grid: "future grid",
    future_init_price: "Initial Price",
    future_current_price: "Current Price",
    future_grid_trend: "Trend",
    future_grid_leverage: "Leverage ratio",
    future_grid_profit: "Profit setting",
    future_grid_loss: "Loss setting",

    long: "Long",
    short: "Short",
    no_trend: "Neutral",

    profit_price: "Profit Price",
    profit_amount: "Profit Amount",
    profit_ratio: "Profit Ratio",
    profit_arbitrage: "Arbitrage $rounds$ rounds, no positions",

    loss_price: "Loss Price",
    loss_amount: "Loss Amount",
    loss_ratio: "Loss Ratio",

    unsetting: "Not Set",
    no_delay: "No Delay",
    delay: "Delay",
    order_state_not_triggered: "Not triggered",
    running_total_profit: "Total profit",
    amount_pnl_rate: "Cumulative PnL%",
    capital_scale: "AUM",
    number_of_buyers: "Copiers",
    accumulated_arbitrage_rounds: "Accumulated Arbitrage Rounds",
    count: " time(s)",
    please_open_this_link: "Please open this link on a device with the Pionex App already installed.",
    history_time: "Time",
    grid_transaction_profit: "Profit",
    tabBar_arbitrage: "Arbitrage",
    trade_limit: "Limit",
    trade_market: "Market",

    //SR
    strateg_Min: "Min",
    strateg_Hour: "Hour",
    sr_switch_momentum: "Momentum Rebalancing",
    sr_switch_momentum_period: "Rotation Period",
    sr_switch_momentum_ratio: "Rotation Ratio",
    sr_switch_mode_type: "Rebalancing Method",
    sr_switch_momentum_period_desc: "Rotate once every $time$",
    sr_switch_momentum_ratio_desc: "Adjust the position ratio to: $percents$ based on the rise of the selected coins",
    sr_enter_alert_trigger_threshold_desc: "Proportion deviated by $percent$",
    sr_rebalance_strategy_mode: "Rebalance every $value$",
    address_seletor_others_open: "Expand",
    address_seletor_others_close: "Close",
    "balance.Coins": "Coins",
    sr_total_coins: "$count$ types of coins",
    sr_switch_momentum_method: "Rebalancing Method",
    sr_switch_momentum_mode: "Rebalance Only When Profit",
    sr_switch_momentum_roi: "Rebalancing Return Rate",
    sr_switch_momentum_sub_type_periodic: "Time",
    sr_switch_momentum_sub_type_threshold: "Return Rate",
    switch_on: "On",
    switch_off: "Off",
    sr_switch_momentum_mode_roi: "The profits ratio start rebalancing",
    grid_share_sr_rebalance_new_count: "Rebalance times",
    not_supported_type: "The current order type is not supported",
    other_type: "Other type",
    no_data: "No Data",
    copy_futures_perp: "Perpetual",
    copy_futures_lower_limit: "Range Lower Limit Price",
    copy_futures_upper_limit: "Range Upper Limit Price",
    copy_futures_grid_mode: "Grid Mode",
    copy_futures_grids_arithmetic: "Arithmetic",
    copy_futures_grids_geometric: "Geometric",
    copy_futures_grids: "Grids",
    copy_futures_open_price: "Open Position Price",
    copy_futures_share: "shared a contract trade follow order",
    copy_futures_open: "Open Position",
    copy_futures_mark_price_triggered: "Mark Price Triggered",
    copy_futures_latest_price_triggered: "Latest Price Triggered",

    futures_grid_v2_share_title: "$name$'s Futures Grid Bot",
    futures_grid_v2_order_create_time: "Order Create Time",
    futures_grid_v2_total_profit: "Current Profit Rate",
    futures_grid_v2_grid_profit_apr: "Grid Arbitrage APR",
    futures_grid_v2_create_order: "Copy the Bot",
    futures_grid_v2_share_code: "$name$'s Invitation Code",
    futures_grid_v2_qr_code: "Scan QR Code to Follow Order",
    download_app_text: "Download Pionex",
    grid_position_share_ratio_percent: "Profit sharing rate",
    grid_futures_share_link_qr_code: "Scan & Follow",

    trade_total_profit_rate: "Total Profit Rate",
    grid_spl_set_delay_exist: "(Delay $delay$s)",
    grid_futures_invited_only_tip: "This bot is only available for users invited by $creator$ to copy.",
    grid_futures_order_closed: "The trader has stopped the bot.",
    spot_grid_v2_share_title: "$name$'s Spot Grid",

    order_start_market_price: "Initial BTC price",
    current_market_price: "Current BTC price",
    sr_coins: "$coins$ etc., a total of $count$ coins",
    sr_title: "$name$'s Rebalancing Bot",
};

export default strings;
