import React from "react";
import { H4, Wrapper } from "../../components/StyleComponent/index";

const OrderVerParamItem = (props) => {
    const { title, value } = props;

    return (
        <Wrapper style={{ justifyContent: "space-between", padding: "14px 12px 0" }}>
            <H4>{title}</H4>
            <H4>{value}</H4>
        </Wrapper>
    );
};

export default OrderVerParamItem;
