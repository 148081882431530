const strings = {
    martingale_bot: "马丁格尔机器人",
    mtg_multi_order_name: "多币马丁机器人",
    grid_v3_bot: "网格机器人",
    annualized_return: "年化收益率",
    lasting: "运行时长",
    grid_transaction: "已完成轮次",
    parameters: "订单参数",
    fund_unit: "份",
    grid_order_lasting_day: "天",
    grid_order_lasting_hour: "小时",
    grid_order_lasting_minute: "分钟",
    grid_order_lasting_second: "秒",
    martingale_take_profit_ratio: "赚多少止盈",
    martingale_price_range: "价格区间",
    martingale_safety_orders: "最大加仓次数",
    martingale_price_scale: "跌多少加仓",
    martingale_order_volume_scale: "加仓倍投倍数",
    trade_trigger_price: "触发价格",
    martingale_take_profit_percentage: "止盈百分比",
    martingale_take_profit_trailing_percentage: "回落多少后卖出",
    martingale_price_scale_trailing_percentage: "反弹多少后买入",
    martingale_DIY_shares: "买入份数",
    martingale_DIY_trailing: "最大反弹",
    trade_signal: "信号",
    martingale_signal_immediately: "立即启动",
    martingale_signal_time_lapse: "延时启动",
    martingale_signal_time_lapse_with_unit: "延时启动($time$秒)",
    copy_bot_list_nnt: "跟单人数",
    copy_bot_list_amount: "跟单金额",
    copy_bot_list_bot_lasting_roi: "创建以来收益率",
    quotation_mark: "“$content$”",
    copy_feed_view_all: "查看详情",

    // new
    share_introduce: "这是我的$symbol$$strategy_bot$", // eg: 这是我的BTC/USDT马丁格尔机器人
    share_nickname: "我是 $nickname$", // eg:  我是Haze
    profit_rate: "利润率",
    snapshot_on: "采用$date$时的盈利数据",
    martingale_rounds: "轮次",
    copy_in_one_click: "一键跟单",
    share_order_closed: "该订单已经关闭",
    martingale_stop_loss_percentage: "止损百分比（总利润）",
    unrealized_stop_loss_percentage: "止损百分比（浮动盈亏）",
    open_app: "正在打开派网APP...",
    number_of_grids: "网格数",
    profit_rate_per_grid: "每格利润率",
    take_profit_price: "止盈价格",
    stop_loss_price: "止损价格",
    price_range: "价格区间",
    transactions: "套利次数",

    default_nickname: "派网用户",

    sr_rules_modify_by_time_interval_none: "暂停平衡",
    sr_rules_modify_title: "平衡条件",
    share_sr_enter_alert_trigger_threshold_desc: "占比变化超过", // eg: 佔比變化超過 10%
    rebalanced_bot: "屯币宝",

    button_cancel: "取消",
    subscription_success_confirm: "去跟单",
    confirm_copy_order_closed_title: "该机器人已关闭",
    confirm_copy_order_closed_continue: "分享者已经关闭该机器人，你确定想要继续跟单吗？",
    base_profit_percent: "$coin$收益率",

    future_grid: "合约网格",
    future_init_price: "开单价格",
    future_current_price: "当前价格",
    future_grid_trend: "方向",
    future_grid_leverage: "杠杆倍数",
    future_grid_profit: "止盈设置",
    future_grid_loss: "止损设置",

    long: "做多",
    short: "做空",
    no_trend: "中性",

    profit_price: "止盈价格",
    profit_amount: "盈利额",
    profit_ratio: "盈利比率",
    profit_arbitrage: "无持仓套利$rounds$次",

    loss_price: "止损价格",
    loss_amount: "亏损额",
    loss_ratio: "亏损比率",

    unsetting: "未设置",
    no_delay: "无延迟",
    delay: "延迟",
    order_state_not_triggered: "未触发",
    running_total_profit: "总利润",
    amount_pnl_rate: "累计收益率",
    capital_scale: "资金规模",
    number_of_buyers: "购买人数",
    accumulated_arbitrage_rounds: "累计套利次数",
    count: "次",
    please_open_this_link: "请将此链接在已安装Pionex App的移动设备中打开。",
    history_time: "时间",
    grid_transaction_profit: "收益",
    tabBar_arbitrage: "套利",
    trade_limit: "限价",
    trade_market: "市价",

    //SR
    strateg_Min: "分钟",
    strateg_Hour: "小时",
    sr_switch_momentum: "动量轮动",
    sr_switch_momentum_period: "轮动周期",
    sr_switch_momentum_ratio: "轮动比例",
    sr_switch_mode_type: "调仓方式",
    sr_switch_momentum_period_desc: "每$time$轮动一次",
    sr_switch_momentum_ratio_desc: "根据所选币种的涨幅，把仓位比例调整为：$percents$",
    sr_rebalance_strategy_mode: "每$value$再平衡一次",
    sr_enter_alert_trigger_threshold_desc: "占比变化超过$percent$",
    address_seletor_others_open: "展开",
    address_seletor_others_close: "收起",
    "balance.Coins": "币种",
    sr_total_coins: "共有$count$个币种",
    sr_switch_momentum_method: "轮动方式",
    sr_switch_momentum_sub_type_periodic: "时间",
    sr_switch_momentum_sub_type_threshold: "收益率",
    sr_switch_momentum_roi: "轮动收益率",
    sr_switch_momentum_mode: "安全模式",
    switch_on: "开启",
    switch_off: "关闭",
    sr_switch_momentum_mode_roi: "开始轮动收益率",
    grid_share_sr_rebalance_new_count: "调仓次数",
    not_supported_type: "当前的订单类型不支持",
    other_type: "其他类型",
    no_data: "无数据",
    copy_futures_perp: "永续",
    copy_futures_lower_limit: "区间下限价格",
    copy_futures_upper_limit: "区间上限价格",
    copy_futures_grid_mode: "网格模式",
    copy_futures_grids_arithmetic: "等差",
    copy_futures_grids_geometric: "等比",
    copy_futures_grids: "格",
    copy_futures_open_price: "开仓价格",
    copy_futures_share: "分享了一个合约交易跟单",
    copy_futures_open: "开仓",
    copy_futures_mark_price_triggered: "标记价格触发",
    copy_futures_latest_price_triggered: "最新价格触发",

    futures_grid_v2_share_title: "$name$ 的合约网格机器人",
    futures_grid_v2_order_create_time: "开单时间",
    futures_grid_v2_total_profit: "当前收益率",
    futures_grid_v2_grid_profit_apr: "网格套利年化",
    futures_grid_v2_create_order: "立即跟单",
    futures_grid_v2_share_code: "$name$ 的邀请码",
    futures_grid_v2_qr_code: "扫二维码跟单",
    download_app_text: "下载派网APP",
    grid_position_share_ratio_percent: "分润比例",
    grid_futures_share_link_qr_code: "扫码立即跟单",

    trade_total_profit_rate: "总利润率",
    grid_spl_set_delay_exist: "(延时$delay$秒)",
    grid_futures_invited_only_tip: "该订单仅限$creator$的邀请用户跟单",
    grid_futures_order_closed: "分享者已将当前订单关闭",

    spot_grid_v2_share_title: "$name$ 的现货网格",

    order_start_market_price: "开单时大盘(BTC)价格",
    current_market_price: "当前大盘(BTC)价格",
    sr_coins: "$coins$...等$count$个币种",
    sr_title: "$name$ 的屯币宝",
};

export default strings;
