import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import CopyButton from "src/components/CopyButton";
import OpenAppLoading from "src/components/OpenAppLoading";
import OrderAnnualized from "src/components/OrderAnnualized";
import OrderVerParamItem from "src/components/OrderVerParamItem";
import ShareHeader from "src/components/ShareHeader";
import Space from "src/components/Space";
import { H3, RadiusWrapper, Wrapper, WrapperColumn } from "src/components/StyleComponent";
import str from "src/i18n/useStr";
import { useShareIdData } from "src/use";
import type { ExchangeGridOrder } from "src/use/types";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";
import { handleGridShareClick, isMobile } from "../../utils";
import { GridTradingUtils } from "trade_lib_wrapper";
import bot_order_none_white from "../../images/bot_order_none_white.png";
import { ShareUserInfo } from "./ShareUserInfo";
import { useAnnualized } from "./useAnnualized";
import { useProfitPercent } from "./useProfitPercent";
import "./index.css";
import CopyBotShareTemplate from "../CopyBot";
import { GridStopType } from "TradeAPILib";
import { useSymbol } from "trade_quotation_lib";

const antIcon = <LoadingOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} spin />;

type GridShareURLParams = {
    share_id: string;
};

const GridV3RobotShareTemplate = () => {
    const { share_id } = useParams<GridShareURLParams>();
    const mobileDevice = isMobile();
    const [showOpenApp, setShowOpenApp] = useState(false);
    const [data, loading] = useShareIdData(share_id);

    const profitPercent = useProfitPercent(data.orderData);

    const symbolInfo = useSymbol(data.orderData?.base, data.orderData?.quote);

    //NOTE either v3 or v4 order, depending on the returned value
    const exchangeGridOrder =
        (data?.orderData?.exchangeGridV3Order || data?.orderData?.exchangeGridV4Order || data?.orderData?.exchangeGridV5Order) ??
        ({} as ExchangeGridOrder);

    const annualized = useAnnualized(exchangeGridOrder, data?.orderData?.timestamp, profitPercent);

    const {
        low: lowOfRange,
        high: highOfRange,
        number: NumberOfGrids,
        trailing: takeProfitPrice,
        stop: stopLossPrice,
        condition: tradeTriggerPrice,
    } = exchangeGridOrder;

    /**
     * 止盈止损类型
     * gridv5: 增加了止盈止损按收益率，收益额的类型，展示有所不同
     * @returns 止损类型，止盈类型，止损延迟，止盈延迟
     */
    const [lossStopType, profitStopType, lossStopDelay, profitStopDelay] = useMemo(() => {
        if (data?.orderData?.exchangeGridV5Order) {
            return [
                data?.orderData?.exchangeGridV5Order.loss_stop_type,
                data?.orderData?.exchangeGridV5Order.profit_stop_type,
                data?.orderData?.exchangeGridV5Order.lossStopDelay,
                data?.orderData?.exchangeGridV5Order.profitStopDelay,
            ];
        }
        return [undefined, undefined, undefined, undefined];
    }, [data]);

    const [slStr, tpStr] = useMemo(() => {
        let _spStr = new SafeDecimal(takeProfitPrice).toString();
        let _slStr = new SafeDecimal(stopLossPrice).toString();

        if (lossStopType) {
            if (lossStopType === GridStopType.profit_amount) {
                _slStr = `${str("running_total_profit")} ${stopLossPrice}`;
            }
            if (lossStopType === GridStopType.profit_ratio) {
                _slStr = `${str("trade_total_profit_rate")} ${NumberCommonUtils.toPercent(
                    new SafeDecimal(stopLossPrice).mul(100),
                    false,
                    true,
                    0,
                )}`;
            }

            if (lossStopDelay) {
                _slStr = `${_slStr} ${str("grid_spl_set_delay_exist", { delay: lossStopDelay })}`;
            }
        }

        if (profitStopType) {
            if (profitStopType === GridStopType.profit_amount) {
                _spStr = `${str("running_total_profit")} ${takeProfitPrice}`;
            }
            if (profitStopType === GridStopType.profit_ratio) {
                _spStr = `${str("trade_total_profit_rate")} ${NumberCommonUtils.toPercent(
                    new SafeDecimal(takeProfitPrice).mul(100),
                    false,
                    true,
                    0,
                )}`;
            }
            if (profitStopDelay) {
                _spStr = `${_spStr} ${str("grid_spl_set_delay_exist", { delay: profitStopDelay })}`;
            }
        }

        return [_slStr, _spStr];
    }, [stopLossPrice, takeProfitPrice, lossStopType, profitStopType, lossStopDelay, profitStopDelay]);

    const priceRange = `${lowOfRange} - ${highOfRange} ${data?.orderData?.quote}`;

    const profitPerGrid = GridTradingUtils.useProfitPerGrid(
        exchangeGridOrder.gridType,
        String(highOfRange),
        String(lowOfRange),
        String(exchangeGridOrder.number),
        "0.0005",
        8,
    );

    const profitPerGridDesc = GridTradingUtils.useProfitPerGridDesc(profitPerGrid);
    const isEBBot = data?.orderData?.originOrderData.bu_order_data?.cate_type === "eb";

    if (isEBBot) {
        return <CopyBotShareTemplate orderType={data.orderData?.orderType} orderId={data.orderData?.copyFrom} />;
    }

    if (showOpenApp) {
        return <OpenAppLoading />;
    }

    //TODO: improve the last condition
    if (
        loading ||
        !data?.orderData ||
        (!data?.orderData?.exchangeGridV3Order && !data?.orderData?.exchangeGridV4Order && !data?.orderData?.exchangeGridV5Order)
    ) {
        return (
            <div>
                <ShareHeader />
                <Wrapper style={{ marginTop: "30%" }}>
                    <Spin indicator={antIcon} />
                </Wrapper>
            </div>
        );
    }

    if (!data.orderData.isRunning) {
        return (
            <div>
                <ShareHeader />
                <WrapperColumn style={{ marginTop: "45%" }}>
                    <img src={bot_order_none_white} style={{ width: 160, height: 160 }} alt={"close_order"} />
                    <H3 style={{ marginTop: 10 }}>{str("share_order_closed")}</H3>
                </WrapperColumn>
            </div>
        );
    }

    return (
        <div style={!mobileDevice ? { maxWidth: 750, margin: "0 auto" } : {}}>
            <ShareHeader />

            <ShareUserInfo
                nickname={data.nickname}
                avatar={data.avatar}
                symbol={`${symbolInfo?.baseDisplay}/${symbolInfo?.quoteDisplay}`}
                strategyBot="grid_v3_bot"
            />
            <Space size={20} />

            <OrderAnnualized
                profitPercent={`${Number(profitPercent) > 0 ? "+" : ""}${profitPercent}%`}
                details={[
                    ["annualized_return", annualized],
                    ["lasting", exchangeGridOrder.triggerTime || data.orderData.timestamp],
                    ["transactions", exchangeGridOrder.trx_history],
                ]}
            />

            <div style={{ marginTop: 20, marginBottom: 10, alignItems: "flex-start", display: "flex", paddingLeft: 18 }}>
                <H3>{str("parameters")}</H3>
            </div>

            <RadiusWrapper style={{ paddingBottom: 14 }}>
                {[
                    ["price_range", priceRange],
                    ["number_of_grids", NumberOfGrids],
                    ["profit_rate_per_grid", profitPerGridDesc],
                ].map(([name, val]) => (
                    <OrderVerParamItem key={name} title={str(name)} value={val} />
                ))}

                {!!takeProfitPrice && <OrderVerParamItem title={str("take_profit_price")} value={tpStr} />}
                {!!stopLossPrice && <OrderVerParamItem title={str("stop_loss_price")} value={slStr} />}
                {!new SafeDecimal(tradeTriggerPrice).equals(0) ? (
                    <OrderVerParamItem title={str("trade_trigger_price")} value={tradeTriggerPrice} />
                ) : null}
            </RadiusWrapper>

            {mobileDevice && <Space size={120} />}

            <CopyButton
                onPress={() => {
                    handleGridShareClick(data, data.orderData?.originOrderData.status, share_id, () => setShowOpenApp(true));
                }}
                sticky={mobileDevice}
            />
        </div>
    );
};

export default GridV3RobotShareTemplate;
