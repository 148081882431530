import { useMemo } from "react";
import { SafeDecimal } from "trade_utils_lib";
import { ExchangeOrder } from "TradeAPILib";

export const useProfitPercent = (orderData: ExchangeOrder | null) =>
    useMemo(() => {
        /*

            利润率：总利润 /  (quote_invest + base_invest * open_price)

            总利润 data.orderData.originOrderData.bu_order_data.total_profit

            quote_invest: data.orderData.originOrderData.bu_order_data.quote_total_investment
            base_invest: data.orderData.originOrderData.bu_order_data.base_total_investment
            open_price: data.orderData.originOrderData.bu_order_data.open_price

            */
        const bu_order_data = orderData?.originOrderData?.bu_order_data;
        if (!bu_order_data) {
            return 0;
        }

        const { total_profit, quote_total_investment, base_total_investment, open_price } = bu_order_data;

        return new SafeDecimal(total_profit)
            .div(new SafeDecimal(quote_total_investment).add(new SafeDecimal(base_total_investment).mul(new SafeDecimal(open_price))))
            .mul(100)
            .toDecimalPlaces(2)
            .valueOf();
    }, [orderData]);
