import React from "react";
import Constants from "src/Constants";
import { H2, H4, WrapperColumn } from "../StyleComponent";

export const DEFAULT_AVATAR = "pionex_app_default.png";

const ShareUserInfo = (props) => {
    const { nickname, avatar, title } = props;
    return (
        <WrapperColumn style={{ marginTop: 20 }}>
            <img
                style={{ width: "7.2rem", height: "7.2rem", borderRadius: "50%" }}
                src={`${Constants.avatarLocationOrigin}${avatar || DEFAULT_AVATAR}`}
                alt={"avatar"}
            />
            <H2 style={{ marginTop: 12 }}>{nickname}</H2>
            <H4 style={{ margin: "2px 20px 0" }}>{title}</H4>
        </WrapperColumn>
    );
};

export default ShareUserInfo;
