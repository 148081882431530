/**
 * @author author
 * @date 2023/2/2
 * @time 14:27
 * @fileName index.tsx
 * @Description:
 */

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";
import { ExchangeOrderType } from "TradeAPILib/dist/src/ExchangeAPIAdapter/TradeTypeDef";
import CopyButton from "../../components/CopyButton";
import OpenAppLoading from "../../components/OpenAppLoading";
import OrderAnnualizedV2 from "../../components/OrderAnnualizedV2";
import ShareHeader from "../../components/ShareHeader";
import ShareUserInfo from "../../components/ShareUserInfo";
import Space from "../../components/Space";
import { H3, Wrapper, WrapperColumn } from "../../components/StyleComponent";
import str from "../../i18n/useStr";
import bot_order_none_white from "../../images/bot_order_none_white.png";
import { useCopyBotShareIdData, useGetBotHistoryChart } from "../../use";
import { copyTradeHandlePress, isMobile } from "../../utils/index";
import { getLastDesc, snapshot_timestamp } from "src/components/OrderAnnualized";
import moment from "moment";
import GridEBTotalProfitLine from "./GridEBTotalProfitLine";
import SRParamsInfo from "../../ShareUIComponentV2/SRParamsInfo";

const antIcon = <LoadingOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} spin />;
const StableCoin = ["USDT", "BUSD", "USDC"];

const CopyBotShareTemplate = ({ orderType, orderId }: { orderType?: string; orderId?: string }) => {
    const routerParams: any = useParams();
    const { order_type = orderType, order_id = orderId } = routerParams as any;
    const mobileDevice = isMobile();
    const [showOpenApp, setShowOpenApp] = React.useState(false);
    const [data, loading] = useCopyBotShareIdData(order_type, order_id);
    const isEBBot = data?.cate_type === "eb";
    const [profitTitle, profitPercent] = React.useMemo(() => {
        let rota = data.rota;
        let title = str("profit_rate");
        if (order_type === ExchangeOrderType.gridV4 || order_type === ExchangeOrderType.gridV5) {
            let coin = data.investCoin;
            if (isEBBot || (!StableCoin.includes(data.investCoin) && data.rota_usdt > data.rota)) {
                rota = data.rota_usdt;
                coin = "USDT";
            }
            title = str("base_profit_percent", { coin });
        }
        return [title, NumberCommonUtils.toPercent(new SafeDecimal(rota).mul(100), false, true)];
    }, [data.investCoin, data.rota, data.rota_usdt, isEBBot, order_type]);

    const chartData = useGetBotHistoryChart(isEBBot ? order_id : "", order_type);
    if (showOpenApp) {
        return <OpenAppLoading />;
    }

    if (loading) {
        return (
            <div>
                <ShareHeader />
                <Wrapper style={{ marginTop: "30%" }}>
                    <Spin indicator={antIcon} />
                </Wrapper>
            </div>
        );
    }

    if (data.status !== "open") {
        return (
            <div>
                <ShareHeader />
                <WrapperColumn style={{ marginTop: "45%" }}>
                    <img src={bot_order_none_white} style={{ width: 160, height: 160 }} alt={"close_order"} />
                    <H3 style={{ marginTop: 10 }}>{str("share_order_closed")}</H3>
                </WrapperColumn>
            </div>
        );
    }

    const isSR = order_type === ExchangeOrderType.SR;

    return (
        <div style={!mobileDevice ? { maxWidth: 750, margin: "0 auto" } : {}}>
            <ShareHeader />
            <ShareUserInfo
                nickname={data.nickname}
                avatar={data.avatar}
                title={str("share_introduce", {
                    symbol: " ",
                    strategy_bot: str("quotation_mark", { content: data.botName }),
                })}
            />
            <Space size={20} />
            {isEBBot ? (
                <div className="wrapper_card">
                    <div className="first_row">
                        <div className="first_field">
                            <div className="first_label">
                                {str("running_total_profit")}
                                <span className="first_unit">USDT</span>
                            </div>
                            <div className={`first_value ${data.rota_usdt >= 0 ? "text_increase" : "text_decrease"}`}>
                                {NumberCommonUtils.toNormalPrice(data.profit_usdt)}
                            </div>
                        </div>
                        <div className="first_field items_end">
                            <div className="first_label">{str("amount_pnl_rate")}</div>
                            <div className={`first_value ${data.rota_usdt >= 0 ? "text_increase" : "text_decrease"}`}>
                                {Number(profitPercent) > 0 ? "+" : ""}
                                {profitPercent}
                                <span className="first_percent">%</span>
                            </div>
                        </div>
                    </div>
                    {!!chartData && <GridEBTotalProfitLine totalProfit={data.rota_usdt} lineData={chartData} />}
                    <div className="second_row">
                        <span className="second_label">{str("accumulated_arbitrage_rounds")}</span>
                        <span className="second_value">
                            {data.exchange_order_paired_count}
                            {str("count")}
                        </span>
                    </div>
                    <div className="grid_fields">
                        <div className="grid_fields_group">
                            <div className="grid_fields_label">{str("capital_scale")}</div>
                            <div className="grid_fields_value">{`${NumberCommonUtils.toNormalPrice(data.investNumAmount)} ${
                                data.investCoin
                            }`}</div>
                        </div>
                        <div className="grid_fields_group">
                            <div className="grid_fields_label">{str("number_of_buyers")}</div>
                            <div className="grid_fields_value">{data.investNum}</div>
                        </div>
                        <div className="grid_fields_group">
                            <div className="grid_fields_label">{str("lasting")}</div>
                            <div className="grid_fields_value" style={{ whiteSpace: "nowrap" }}>
                                {getLastDesc(data.orderCreateTime)}
                            </div>
                        </div>
                    </div>
                    <div className="timestamp_text">
                        {str("snapshot_on", {
                            date: moment(snapshot_timestamp).format("YYYY/MM/DD HH:mm:ss"),
                        })}
                    </div>
                </div>
            ) : (
                <OrderAnnualizedV2
                    profitTitle={profitTitle}
                    profitPercent={profitPercent}
                    details={[
                        ["copy_bot_list_nnt", data.investNum],
                        ["lasting", data.orderCreateTime],
                        ["copy_bot_list_amount", `${NumberCommonUtils.toNormalPrice(data.investNumAmount)} ${data.investCoin}`],
                    ]}
                />
            )}
            {isSR && <SRParamsInfo data={data.parseOrderParams || []} />}
            {mobileDevice && <Space size={120} />}

            <CopyButton
                title={isSR ? str("copy_in_one_click") : str("copy_feed_view_all")}
                onPress={() => {
                    copyTradeHandlePress(data, routerParams.share_id, order_id, order_type, () => setShowOpenApp(true));
                }}
                sticky={mobileDevice}
            />
        </div>
    );
};

export default CopyBotShareTemplate;
