import axios from "axios";
import { useEffect, useState } from "react";
import { forkJoin, from, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { Network, requestWithRetry } from "trade_utils_lib";
import { BotSignalItem, ExchangeID, ExchangeOrder, ExchangeOrderType, TradeUtils } from "TradeAPILib";
import Constants from "../Constants";
import { langEnum } from "../i18n/types";
import str from "../i18n/useStr";
import { queryLang } from "../utils";
import { getInviteCode } from "../utils/getInviteCode";
import type { CopyBotShareInfo, OrderShareResponse, ShareOrderInfo } from "./types";
import { convertShareDataV2, PlatformType } from "trade_lib_wrapper";

const DEFAULT_AVATAR = "pionex_app_default.png";

export const useShareIdDataForMartingale = (shareId) => {
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (!shareId) {
            setLoading(false);
            setError("share_id_error");
        }
        axios
            .get(`${Constants.tradeServiceHost}/orders/order_share/${shareId}/`, {
                timeout: 10000,
            })
            .then((res) => {
                setLoading(false);
                setError(undefined);
                if (res.data.code === 0) {
                    const resData = res.data.data;
                    const nickname = resData.user_info.nickname;
                    const avatar = resData.user_info.head_path;
                    const orderData = resData.bu_order;
                    setData({
                        nickname,
                        avatar,
                        orderData,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(error);
            });
    }, [shareId]);

    return [data, loading, error];
};

export const useMartingaleSignal = (data: any): [BotSignalItem | undefined, boolean] => {
    const [result, setResult] = useState<BotSignalItem>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (data.orderData && data.orderData.bu_order_data && data.orderData.bu_order_data.portfolio[0]) {
            const signal_type = data.orderData.bu_order_data.portfolio[0].signal_type;
            let url = "";

            let appLang = langEnum.cn;
            const sysLang = queryLang();
            if (sysLang === langEnum.cn || sysLang === langEnum.tw) {
                appLang = sysLang;
            } else {
                appLang = "en";
            }
            if (signal_type && signal_type !== "immediately" && signal_type !== "time_lapse") {
                url = `${Constants.signalHost}/signal/detail?app_lang=${appLang}&signal_type=${signal_type}`;
                axios
                    .get(url, {
                        timeout: 10000,
                    })
                    .then((res) => {
                        setLoading(false);
                        if (res.data.code === 0) {
                            const signals = res.data.data;
                            if (signals) {
                                setResult(signals as BotSignalItem);
                            }
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                    });
            }
        }
    }, [data.orderData]);

    return [result, loading];
};

const DefaultShareOrderInfo: ShareOrderInfo = {
    nickname: str("default_nickname"),
    avatar: `https://avatar.pionex.com/${DEFAULT_AVATAR}`,
    botType: ExchangeOrderType.gridV3,
    orderData: null,
};

// 后续其他机器人用这个
export const useShareIdData = (shareId: string): [ShareOrderInfo, boolean] => {
    const [data, setData] = useState(DefaultShareOrderInfo);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const url = `${Constants.tradeServiceHost}/orders/order_share/${shareId}/`;

            try {
                const res = await axios.get<OrderShareResponse>(url, {
                    timeout: 10000,
                });

                const responseData = res.data?.data;
                const orderData: ExchangeOrder = TradeUtils.convertToExchangeOrder(responseData.bu_order)!;

                setData({
                    nickname: responseData.user_info.nickname,
                    avatar: responseData.user_info.head_path,
                    botType: orderData.orderType,
                    orderData,
                });
            } catch (error) {
                // Do nothing for now
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [shareId]);

    return [data, loading];
};

export function useGetBotOverview(order_id?: string, order_type?: ExchangeOrderType) {
    const [overviews, setOverviews] = useState<any>(undefined);
    useEffect(() => {
        if (!order_id || !order_type) {
            return;
        }
        let appLang = langEnum.cn;
        const sysLang = queryLang();
        if (sysLang === langEnum.cn || sysLang === langEnum.tw) {
            appLang = sysLang;
        } else {
            appLang = "en";
        }
        axios
            .get(`${Constants.kolFHost}/trading_bot_order?bu_order_id=${order_id}&bu_order_type=${order_type}&app_lang=${appLang}`, {
                timeout: 10000,
            })
            .then((res) => {
                setOverviews(res?.data?.data);
            })
            .catch(() => {});
    }, [order_id, order_type]);
    return overviews;
}

export function useGetBotHistoryChart(order_id?: string, order_type?: ExchangeOrderType) {
    const [data, setData] = useState<any>(undefined);
    useEffect(() => {
        if (!order_id || !order_type) {
            return;
        }
        const $ob = Network.get({
            url: `${Constants.kolTApi}/trading_bot/history_chart?bu_order_id=${order_id}&bu_order_type=${order_type}`,
        })
            .pipe(
                catchError((res) => {
                    //原始单为gridv4 ，跟出来的girdv5 ，但是在订单列表中无法获取原始单的订单类型
                    if (res.code === 50001300) {
                        if (order_type === ExchangeOrderType.gridV5) {
                            return Network.get({
                                url: `${Constants.kolTApi}/trading_bot/history_chart?bu_order_id=${order_id}&bu_order_type=${ExchangeOrderType.gridV4}`,
                            });
                        } else {
                            return of(res);
                        }
                    } else {
                        return of(res);
                    }
                }),
            )
            .subscribe({
                next: (res) => {
                    setData(res.results);
                },
                error: () => {},
            });
        return () => {
            $ob.unsubscribe();
        };
    }, [order_id, order_type]);
    return data;
}

// 跟单机器人用这个
export const useCopyBotShareIdData = (order_type, order_id): [CopyBotShareInfo, boolean, string | undefined] => {
    const [data, setData] = useState<CopyBotShareInfo>({
        nickname: str("default_nickname"),
        avatar: `https://avatar.pionex.com/${DEFAULT_AVATAR}`,
        botType: order_type,
        botName: "",
        investNum: 0, //跟单人数
        investNumAmount: "0", //跟单金额
        investCoin: "",
        orderCreateTime: 0,
        baseList: [],
        status: "",
        rota: 0,
        rota_usdt: 0,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (!order_id || !order_type) {
            setLoading(false);
            setError("share_id_error");
        }
        let appLang = langEnum.cn;
        const sysLang = queryLang();
        if (sysLang === langEnum.cn || sysLang === langEnum.tw) {
            appLang = sysLang;
        } else {
            appLang = "en";
        }

        const obArray: any[] = [
            Network.get({
                url: `${Constants.kolFHost}/trading_bot_order?bu_order_id=${order_id}&bu_order_type=${order_type}&app_lang=${appLang}`,
            }),

            Network.get({
                url: `${Constants.kolTApi}/statistic/overview?bu_order_id=${order_id}&bu_order_type=${order_type}&app_lang=${appLang}`,
            }),
        ];
        if (order_type === ExchangeOrderType.gridV4 || order_type === ExchangeOrderType.gridV5) {
            obArray.push(
                Network.get({
                    url: `${Constants.kolTApi}/trading_bot/overview?bu_order_id=${order_id}&bu_order_type=${order_type}&app_lang=${appLang}`,
                }).pipe(
                    catchError((res) => {
                        //原始单为gridv4 ，跟出来的girdv5 ，但是在订单列表中无法获取原始单的订单类型
                        if (res.code === 50001300) {
                            if (order_type === ExchangeOrderType.gridV5) {
                                return Network.get({
                                    url: `${Constants.kolTApi}/trading_bot/overview?bu_order_id=${order_id}&bu_order_type=${ExchangeOrderType.gridV4}&app_lang=${appLang}`,
                                });
                            } else {
                                return of(res);
                            }
                        } else {
                            return of(res);
                        }
                    }),
                ),
            );
        } else {
            obArray.push(
                from(
                    new Promise((resolve, reject) => {
                        // @ts-ignore
                        resolve(null);
                    }),
                ),
            );
        }

        const $ob = forkJoin(obArray).subscribe({
            next: (res) => {
                const responseData = res[0] || {};
                const statisticData = (res as any)?.[1] || {};
                const overviewData = (res as any)?.[2] || {};
                setLoading(false);

                let parseOrderParams;
                try {
                    parseOrderParams = convertShareDataV2(
                        PlatformType.web,
                        {
                            bu_order: {
                                ...responseData?.order_data?.payload,
                                ...{ bu_order_type: order_type, bu_order_id: order_id },
                            },
                            user_info: {},
                        },
                        str,
                    );
                } catch (err) {}

                setData({
                    nickname: responseData.nick_name,
                    avatar: responseData.avatar,
                    botType: responseData.bu_order_type,
                    botName: responseData.bot_name,
                    investNum: responseData.invest_num,
                    investNumAmount: responseData.invest_num_amount,
                    orderCreateTime: responseData.order_create_time,
                    status: responseData.order_status,
                    baseList: responseData.base_list,
                    investCoin: responseData.investment_token,
                    rota: statisticData.rota,
                    rota_usdt: statisticData.rota_usdt,
                    cate_type: responseData.cate_type,
                    exchange_order_paired_count: responseData?.order_data?.payload?.bu_order_data?.exchange_order_paired_count,
                    profit_usdt: overviewData.profit_usdt || "",
                    parseOrderParams: parseOrderParams,
                });
            },
            error: (error) => {
                setLoading(false);
                setError(error);
            },
        });
        return () => {
            $ob.unsubscribe();
        };
    }, [order_id, order_type]);

    useEffect(() => {
        //信号机器人容错处理
        if (order_type === ExchangeOrderType.SC) {
            const inviteCode = getInviteCode();
            const redirectUrl = `${Constants.mainHost}/trade?fromShare=1&r=${inviteCode || ""}`;
            if (!order_id) {
                window.open(redirectUrl, "_self");
            } else {
                axios
                    .get(`${Constants.kolFHost}/copy_trade_robot_check?bu_order_id=${order_id}&bu_order_type=${order_type}`, {
                        timeout: 10000,
                    })
                    .then((res) => {
                        if (!res?.data?.data?.exists) {
                            //未上架
                            window.open(redirectUrl, "_self");
                        }
                    })
                    .catch((e) => {});
            }
        }
    }, [order_id, order_type]);

    return [data, loading, error];
};

export const useGetValidResource = (url: string, fallbackUrl: string) => {
    const [valid, setValid] = useState<boolean>();

    useEffect(() => {
        const img = new Image();
        img.onload = function () {
            setValid(true);
        };
        img.onerror = function () {
            setValid(false);
        };
        img.src = url;
    }, [url]);

    if (valid === undefined) {
        return null;
    }
    return valid ? url : fallbackUrl;
};

export function useImage(src: string, defaultSrc: string) {
    const [url, setUrl] = useState("");

    useEffect(() => {
        const img = new Image();
        img.onload = function () {
            setUrl(img.src);
        };
        img.onerror = function () {
            setUrl(defaultSrc);
        };
        img.src = src;
    }, [defaultSrc, src]);

    return url;
}

/**
 * 获取某个币对在某个时间点的价格
 * @param base base
 * @param quote quote
 * @param time 时间，单位是ms
 * @returns 收盘价格及loading状态
 */
export function useSymbolPrice(base: string, quote: string, time: number) {
    const [value, setValue] = useState<number>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!time) {
            return;
        }
        const start = Math.floor(time / 1000);
        const $ob = requestWithRetry(
            () =>
                Network.get({
                    url: "/kline/query_unite_candle_data",
                    data: {
                        base,
                        quote,
                        market: ExchangeID.PIONEXV2,
                        interval: "1m",
                        start: start,
                        end: start + 60,
                        from: "web",
                    },
                }),
            {
                maxRetryCount: 3,
                name: "querySymbolPerformance",
            },
        ).subscribe({
            next: (data) => {
                setValue(data.history_price?.[0]?.close);
                setLoading(false);
            },

            error: (error) => {
                setLoading(false);
            },
        });
        return () => $ob.unsubscribe();
    }, [base, quote, time]);

    return { value, loading };
}
