import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import * as React from "react";
import { useParams } from "react-router-dom";
import { OrderAnnualizedData, UIType, UserInfoData } from "trade_lib_wrapper";
import CopyButton from "../../components/CopyButton";
import OpenAppLoading from "../../components/OpenAppLoading";
import ShareHeader from "../../components/ShareHeader";
import Space from "../../components/Space";
import { H3, Wrapper, WrapperColumn } from "../../components/StyleComponent";
import str from "../../i18n/useStr";
import bot_order_none_white from "../../images/bot_order_none_white.png";
import OrderAnnualized from "../../ShareUIComponentV2/AnnualizedInfo";
import UserInfo from "../../ShareUIComponentV2/UserInfo";
import { useShareIdDataV2 } from "../../templates/TemplateV2/use";
import { isMobile } from "../../utils";
import { handleSharePress } from "../../utils/index";
import SRParamsInfo from "../../ShareUIComponentV2/SRParamsInfo";

const antIcon = <LoadingOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} spin />;

const TemplateV2 = () => {
    const mobileDevice = isMobile();
    const routerParams = useParams();
    const { share_id } = routerParams as any;
    const [data, loading, _, originData] = useShareIdDataV2(share_id);
    const [showOpenApp, setShowOpenApp] = React.useState(false);

    if (originData?.bu_order?.close_time) {
        return (
            <div>
                <ShareHeader />
                <WrapperColumn style={{ marginTop: "45%" }}>
                    <img src={bot_order_none_white} style={{ width: 160, height: 160 }} alt={"close_order"} />
                    <H3 style={{ marginTop: 10 }}>{str("share_order_closed")}</H3>
                </WrapperColumn>
            </div>
        );
    }

    if (showOpenApp) {
        return <OpenAppLoading />;
    }

    if (loading) {
        return (
            <div>
                <Wrapper style={{ marginTop: "30%" }}>
                    <Spin indicator={antIcon} />
                </Wrapper>
            </div>
        );
    }

    return (
        <div style={!mobileDevice ? { maxWidth: 750, margin: "0 auto" } : {}}>
            <ShareHeader />
            {data.map((item, index) => {
                switch (item.uiType) {
                    case UIType.pnlInfo:
                        const pnlData = item.data as OrderAnnualizedData;
                        return (
                            <div key={`${item.uiType}_${index}`}>
                                <Space size={20} />
                                <OrderAnnualized
                                    key={`${item.uiType}_${index}`}
                                    profitPercent={pnlData.annualized}
                                    details={pnlData.details}
                                />
                            </div>
                        );

                    case UIType.userInfo:
                        const userInfoData = item.data as UserInfoData;
                        return (
                            <UserInfo
                                key={`${item.uiType}_${index}`}
                                nickname={userInfoData.nickname}
                                avatar={userInfoData.avatar}
                                symbol={userInfoData.symbol}
                                strategy={userInfoData.strategy}
                            />
                        );

                    default:
                        return null;
                }
            })}
            <SRParamsInfo data={data} />

            <Space size={100} />

            <CopyButton onPress={() => handleSharePress(originData, share_id, () => setShowOpenApp(true))} sticky={mobileDevice} />
        </div>
    );
};

export default TemplateV2;
